import React, {useContext, useEffect, useRef, useState} from "react";
import {
  ActivationAlgo,
  AdminReportType, DateHelper, ISignalAlert,
  ISignalAlertActivation, ISignalGroup as Entity,
  ISignalGroup,
  IStockSymbolAdmin,
  MathEx,
  SignalAlertPerfTypeEnum,
  SignalAlertPerfTypeEnumHelper, SignalGroupTypeEnum,
  StockDirectionType, StockSignalsEnum, StockSignalsHelper,
} from "predictagram-lib";
import {Form, Formik} from "formik";
import {FieldWithError} from "components/common";
import {Spinner} from "components/common/Spinner";
import Criteria = ActivationAlgo.Criteria;
import ComboCondCompare = ActivationAlgo.ComboCondCompare;
import IReport = ActivationAlgo.IReport;
import IComboIndicator = ActivationAlgo.IComboIndicator;
import {MessengerContext, Severity} from "../../../common/messenger";
import {useSearchParams} from "react-router-dom";
import {ApiStateImpl, IApiState} from "../../../../_constants/APIState";
import {useNavigate} from "react-router";
import {adminApiServiceCommon, adminSignalAlertApiService} from "../../../../services/AdminApiService";
import {UtilsHelper} from "predictagram-lib/dist/utils/utils.helper";
import {UrlHelper} from "../../../../_utils/UrlHelper";
import {getCoreRowModel, getSortedRowModel, SortingState, useReactTable} from "@tanstack/react-table";
import {useApiServiceState} from "../../../../_hooks/useApiServiceState";
import {ReactTableHelper} from "../../../../_utils/ReactTableHelper";
import {editSignalAlertLink} from "../alerts/List";
import {DataGrid} from "../../common/DataGrid";

export enum SubmitModeEnum {
  CREATE = 1,
  UPDATE = 2,
}
interface Options extends ISignalGroup {

}

export const AdminSignalGroupPage: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('id')!==null ? parseInt(searchParams.get('id') as any): null;

  const [initialValues, setInitialValues] = useState<Partial<Options>>({
  });

  const navigate = useNavigate();

  const [state, setState] = useState<IApiState>(ApiStateImpl.IDLE);

  useEffect(() => {
    if (itemId === null) {

    } else {
      (async () => {
        const item = await adminApiServiceCommon.getSignalGroupById(itemId);
        setInitialValues(item);
      })()
    }
  }, [itemId])

  const submitWrap = async (searchOptions: Options, submitMode: SubmitModeEnum) => {
    try {
      await submit(searchOptions, submitMode);
    } catch (e: any) {
      msgrContext.setMessage({ body: e.message }, true, Severity.FATAL);
      setState(ApiStateImpl.error(e));
    }
  }


  const submit = async (dataIn: Options, submitMode: SubmitModeEnum) => {

    const payload = {
      name: dataIn.name,
      description: dataIn.description,
    } as Options;
    if (submitMode===SubmitModeEnum.CREATE) {
      payload.typeId = SignalGroupTypeEnum.PRIVATE;
    }

    // new form or save as
    if ([SubmitModeEnum.CREATE].includes(submitMode)) {
      const result = await adminApiServiceCommon.createSignalGroup(payload);
      msgrContext.setMessage({ body: 'Created #' + result.id }, true, Severity.NORMAL);
      navigate(UrlHelper.getAdminSignalGroup(result.id));
      return;
    } else
    // update existing
    if (submitMode === SubmitModeEnum.UPDATE) {
      if (itemId === null) {
        msgrContext.setMessage({ body: 'Could not update. Missing id' }, true, Severity.FATAL);
        return;
      }
      const result = await adminApiServiceCommon.updateSignalGroupById(itemId, payload);
      msgrContext.setMessage({ body: 'Updated #' + result.id }, true, Severity.NORMAL);
    }

  }

  return (<>
    <div className="strategy-profit mt-3 d-flex justify-content-center">
      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 'd-block'}`}>
        <SignalGroupForm  initialValues={initialValues} onClick={submitWrap} isEditing={itemId !== null} />
      </div>
    </div>
    <div className="strategy-profit mt-3 d-flex justify-content-center">
      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 'd-block'}`}>
        {itemId &&
          <SignalGroupAlerts groupId={itemId} />
        }
      </div>
    </div>
    </>

  );
};

export const SignalGroupAlerts: React.FunctionComponent<{groupId:number}> = ({groupId}) => {
  const msgrContext = useContext(MessengerContext);
  const supplier = async() => {
    const data:ISignalAlert[] = [];
    const group = await adminApiServiceCommon.getSignalGroupById(groupId);
    for (const alertId of group.alertIds||[]) {
      const signalAlert: ISignalAlert = await adminSignalAlertApiService.getById(alertId);
      data.push(signalAlert);
    }
    return data;
  }
  //
  // const statuses = Array.from(StatusEnumHelper.getNames().entries()).map(([s,name])=>{
  //   return {label:name,value:s}
  // });

  const deleteItem = async(id:number)=>{
    if (window.confirm(`Delete item #${id}?`)) {
      const res = await addRemove(id, 'remove');
      msgrContext.setMessage({ body: res?'Removed':'Not Removed' }, true);
      if (res) {
        reload();
      }
    }
  }

  const addAlert = async()=>{
    if (!currentAlertId) {
      msgrContext.setMessage({body: 'Empty Alert Id'}, true);
      return;
    }
    const res = await addRemove(currentAlertId as number, 'add');
    msgrContext.setMessage({ body: res?'Added':'Not added, wrong id?' }, true);
    if (res) {
      setCurrentAlertId('' as any);
      reload();
    }
  }
  const addRemove = async(alertId:number, action:'add'|'remove')=>{
    try {
      return await adminApiServiceCommon.addRemoveSignalGroupAlert(groupId, alertId, action);
    } catch (e: any) {
      msgrContext.setMessage({body: e.message}, true, Severity.FATAL);
    }

  }

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [currentAlertId, setCurrentAlertId] = React.useState<number>();
  const {items, state, setSupplier, reload} = useApiServiceState<ISignalAlert[]>(()=>supplier());
  const columnHelper = ReactTableHelper.createHelper<ISignalAlert>();
  const columns = [
    columnHelper.columnBuilder('id', {header: 'ID'}).number().build(),
    columnHelper.columnBuilder('name', {header: 'Name'}).build(),
    columnHelper.columnBuilder('buttons', {
      header: '',
      cell: (info)=>{
        const id = parseInt(info.cell.row.getValue('id'));
        return (<>
            <div className="d-flex gap-1">
              <div className="d-flex flex-column gap-3"><button className="btn btn-secondary" onClick={()=>deleteItem(id)}>Delete</button></div>
            </div>
          </>
        )
      }
    }).build(),
  ];


  const table = useReactTable<ISignalAlert>({
    data: items || [],
    columns: columns as any,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {sorting},
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center gap-2">
          {/*<div className="gap-2">Group Alerts</div>*/}
          <div>Alert ID: </div>
          <div>
            <input name="name" className="form-control" type="input" size={5} value={currentAlertId} onChange={evt=>setCurrentAlertId(evt.target.value as any)} />
          </div>
          <div>
            <button className="btn btn-secondary" onClick={addAlert}>Add Alert</button>
          </div>
      </div>

      <div className="d-flex justify-content-between align-items-end">
        {state.isLoading() && <Spinner />}
        {state.isError() && <div>{state.error()?.message}</div>}
        {state.isLoaded() &&
         <DataGrid table={table}/>
        }
      </div>
    </>
  );
};


export const SignalGroupForm: React.FunctionComponent<{
  initialValues: Partial<Options>,
  onClick: (search: any, submitMode: SubmitModeEnum) => Promise<void>,
  isEditing: boolean,
}> = ({ initialValues, onClick, isEditing}) => {
  const msgrContext = useContext(MessengerContext);
  const inputValues = UtilsHelper.createCopy(initialValues);
  const defaultValues: Partial<Options> = {};
  let initVals = Object.assign(defaultValues, inputValues) as Options;
  const [submitMode, setSubmitMode] = useState<SubmitModeEnum>(SubmitModeEnum.CREATE);

  useEffect(() => {
    setSubmitMode(inputValues != null ? SubmitModeEnum.UPDATE : SubmitModeEnum.CREATE)
  }, [inputValues])


  const onSubmit = async (optsIn: Options) => {
    return await onClick(optsIn, submitMode);
  }

  return (
    <div className="activation-combo-form">
      <div className="page-title mb-3">Signal Group</div>

      <Formik initialValues={initVals} enableReinitialize onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          return <Form>
            <div className="d-flex justify-content-between align-items-end ">
              <div className="form-group">
                <FieldWithError size={30} errors={errors} touched={touched} fieldName="name" label="Name"  />
              </div>
              <div className="form-group">
                <FieldWithError size={40} errors={errors} touched={touched} fieldName="description" label="Description" />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center gap-3">
              {isSubmitting && <Spinner minHeight={50} />}
              {isEditing ?
               <div className="d-flex gap-2">
                 <button type="submit" onClick={() => {setSubmitMode(SubmitModeEnum.UPDATE)}} className="btn btn-primary p-2" disabled={isSubmitting}>Update</button>
               </div>
                         :
               <button type="submit" onClick={() =>  {setSubmitMode(SubmitModeEnum.CREATE)}} className="btn btn-primary p-2" disabled={isSubmitting}>Create New</button>
              }
            </div>
          </Form>
        }}

      </Formik>
    </div>
  )
};
