import { useAdminAutoTrades } from '_hooks/useAdminAutoTrades';
import { ErrorMessage } from 'components/common/ErrorMessage';
import { Spinner } from 'components/common/Spinner';
import React from 'react'
import { useParams } from 'react-router-dom'
import { ChartOverlay } from './ChartOverlay';
import { useAdminAutoTradeSetupSingle } from '_hooks/useAdminAutoTradeSetupSingle';
import { coloredSignalNameMapper } from '../common/ColoredSignal';
import {getAnalysisDefaultLines, getAnalysisOpenCloseSignals} from "../common/helper";


const SetupDetail: React.FunctionComponent<{ tradeSetupId: number }> = ({ tradeSetupId }) => {

  const { api } = useAdminAutoTradeSetupSingle(tradeSetupId);

  if (api.apiState.isLoaded) {
    const signals = getAnalysisOpenCloseSignals(api.data.signalAlert.data);
    return <>
      <div className="d-flex gap-2">
        <div>
          <div className="fw-bold">Open Signals</div>
          <div>
            {signals && signals.openSignals.map((v,i)=>coloredSignalNameMapper(v,i))}
          </div>
        </div>

        <div>
          <div className="fw-bold">Close Signals</div>
          <div>
            {signals && signals.closeSignals.map((v,i)=>coloredSignalNameMapper(v,i))}
          </div>
        </div>
      </div>
    </>
  }

  if (api.apiState.isError) {
    return <ErrorMessage>{api.error}</ErrorMessage>
  }

  if (api.apiState.isLoading) {
    return <Spinner minHeight={15} />
  }

  return <></>
}


export const ConsensusOrdersPage: React.FunctionComponent = () => {

  const tradeId = useParams().tradeId as string;

  const tradeApi = useAdminAutoTrades({ autoTradeId: parseInt(tradeId) });

  if (tradeApi.apiState.isLoaded) {

    if (tradeApi.data.length === 0) {
      return <div>Trade ID not found</div>
    }

    const t = tradeApi.data[0];
    return (
      <div>
        <div className="page-title">{t.setupName}</div>
        <SetupDetail tradeSetupId={t.setupId} />
        <div className="my-3">
          <ChartOverlay tradeSetupId={t.setupId} onEscape={() => { }} symbolId={t.stockSymbolId} trades={[t]} />
        </div>
      </div>
    )
  }

  if (tradeApi.apiState.isLoading) {
    return <Spinner minHeight={15} />
  }

  if (tradeApi.apiState.isError) {
    return <ErrorMessage>{tradeApi.error}</ErrorMessage>
  }

  return <></>
}
