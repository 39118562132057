import axios from 'axios';
import { IApiService } from 'interfaces';
import { AuthenticationService } from 'services';
import {adminAutoTradeSetupService, adminSignalAlertApiService} from "../AdminApiService";
import {IAutoTradeSetup, ISignalAlert} from "predictagram-lib";
import {IAutoTradeSetupEx} from "../../interfaces/IAutoTradeSetupEx";

abstract class BaseApiService<T> implements IApiService<T> {

  protected singleUrl: string; // endpoint that handles a single row
  protected allUrl: string; // endpoint that handles multiple rows
  
  constructor(
    protected apiBaseUrl:string, 
    protected singleEndpoint: string, 
    protected allEndpoint: string,
    protected authenticationService: AuthenticationService) {

    this.allUrl = `${this.apiBaseUrl}${this.allEndpoint}`;
    this.singleUrl = `${this.apiBaseUrl}${this.singleEndpoint}`;

  }

  async getAll(withAuth: boolean = true, usePost: boolean = false): Promise<T[]> {
      let response: any;

    try {

      if (usePost) {
        response = await axios.post<T[]>(this.allUrl, {}, withAuth ? await this.authenticationService.getAuthHeader(): {});
      } else {
        response = await axios.get<T[]>(this.allUrl, withAuth ? await this.authenticationService.getAuthHeader(): {});
      }
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as T[];
    } catch (error: any) {
      throw Error(error.message);
    }

  }

  async getById(id: number, withAuth: boolean = true): Promise<T> {
    const url = `${this.singleUrl}/${id}`;
    try {
      const response: any = await axios.get<T>(url, withAuth ? await this.authenticationService.getAuthHeader(): {});
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async create(item: T, withAuth: boolean = true): Promise<T> {
    try {
      const response: any = await axios.post<T>(this.singleUrl, item, withAuth ? await this.authenticationService.getAuthHeader() : undefined);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (err: any) {
      throw Error(err.message);
    }

  }

  async update(item: T, id: number): Promise<T> {
    const url = `${this.singleUrl}/${id}`;
    try {
      const response: any = await axios.put<T>(url, item, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (err: any) {
      console.error('update error', {err});
      throw Error(err.message);
    }

  }

  async delete(id: number): Promise<T> {
    const url = `${this.singleUrl}/${id}`;
    try {
      const response: any = await axios.delete<T>(url, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.success === false) {
        throw Error('API could not delete data.');
      }
      return response.data.success as T;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async httpGet<T> (url: string): Promise<T> {
    try {
      const response: any = await axios.get<T>(url, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error);
      }
      return response.data.data as T;
    } catch (error: any) {
      throw Error(error);
    }
  }

}

export { BaseApiService }
