import {IBKRSide} from "../dict/auto-trade/ibkr-side.enum";
import {IBKROrderType} from "../dict/auto-trade/ibkr-order-type.enum";
import {OptionType} from "../dict/option-type.enum";
import {AutoTradePositionTypeEnum, AutoTradeSecurityTypeEnum, AutoTradeSetupStatusEnum} from "../dict";
import {Analysis, IAutoTrade, IAutoTradeSetup, ISignalAlert, ITradeTemplate, StockDirectionType} from "../interface";
import {AnalysisHelper} from "../analysis";
import {MathEx} from "./math.ex";

export class AutoTradeHelper {

    public static MAX_TRADES_PER_DAY = 4;
    public static MAX_TRADES_PER_INTERVAL = 1;
    public static TRADES_INTERVAL_MIN = 390;
    public static optionsInitialValues:ITradeTemplate = {
        // contracts: null,
        strikePriceOffset: 0,
        minExpirationDays: 2,
        maxExpirationDays: 6,
        // tradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
        ibkr: {
            side: IBKRSide.BUY,
            orderType: IBKROrderType.LMT_PB,
        },
        optionType: OptionType.CALL,
    };

    static getDefaultOptionType (setupOptions: Analysis.InputParams) : OptionType {
        const dir = AnalysisHelper.direction(setupOptions);
        return  dir === StockDirectionType.DOWN ? OptionType.PUT : OptionType.CALL;
    }

    static createTemplates(signalAlert:ISignalAlert, statusId: AutoTradeSetupStatusEnum) {
        const defaultValues = AutoTradeHelper.optionsInitialValues;
        // const analysisSetup: Partial<TradeSetupFilterOptions> = signalAlert.data;
        const optionType = this.getDefaultOptionType(signalAlert.data);
        const defOrderType = defaultValues.ibkr?.orderType
        const optionTemplate: ITradeTemplate = {
            maxExpirationDays: defaultValues.maxExpirationDays as number,
            minExpirationDays: defaultValues.minExpirationDays as number,
            strikePriceOffset: defaultValues.strikePriceOffset as number,
            optionType: optionType,
            ibkr: {
                side: IBKRSide.BUY, // BUY for both CALL and PUT
                orderType: defOrderType,
            }
        };
        const equityTemplate = {
            ibkr: {
                side: optionType===OptionType.CALL?IBKRSide.BUY:IBKRSide.SELL,
                orderType: defOrderType,
            }
        } as ITradeTemplate;
        const prepareSetup = (securityType:AutoTradeSecurityTypeEnum, template:ITradeTemplate)=>{
            const newTradeSetup: IAutoTradeSetup = {
                statusId: statusId,
                tradeSecurityTypeId: securityType,
                name: signalAlert.name,
                signalAlertId: signalAlert.id,
                maxActiveTrades: 2,
                quantityPerTrade: 1,
                maxTradesPerDay: AutoTradeHelper.MAX_TRADES_PER_DAY,
                maxTradesPerInterval: AutoTradeHelper.MAX_TRADES_PER_INTERVAL,
                tradesIntervalMin: AutoTradeHelper.TRADES_INTERVAL_MIN,
                tradeTemplate: template,
                // analysisSetup: analysisSetup
            } as IAutoTradeSetup;
            return newTradeSetup
        }
        return [
            prepareSetup(AutoTradeSecurityTypeEnum.OPTION, optionTemplate),
            prepareSetup(AutoTradeSecurityTypeEnum.EQUITY, equityTemplate),
        ] as IAutoTradeSetup[];
    }

    static calcProfitLossIbkrTrades(trades: IAutoTrade[]) {
        const data = trades.reduce((total, trade) => {
            return {
                profitLoss: total.profitLoss + AutoTradeHelper.calcRealizedProfitLossTradeIbkr(trade),
                invested: total.invested + (trade.ibkr.openPriceAvg * trade.ibkr.closedQuantity * this.multiplier(trade)) ,
            }
        }, {
            profitLoss: 0,
            invested: 0,
        });
        return {
            profitLossAmount: data.profitLoss,
            investedAmount: data.invested,
            profitLossRate: data.invested ? MathEx.round(data.profitLoss/data.invested, 5) : 0
        }
    }

    static calcRealizedProfitLossTradeIbkr(trade: IAutoTrade) {
        return this.calcProfitLoss({
            open:trade.ibkr.openPriceAvg,
            close:trade.ibkr.closePriceAvg,
            quantity:trade.ibkr.closedQuantity,
            multiplier:this.multiplier(trade),
            isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
        });
    }

    static calcRealizedData(item: IAutoTrade) {
        const profitLossAmount = item.closeQuantity ? AutoTradeHelper.calcProfitLoss({
            isLong:item.positionTypeId===AutoTradePositionTypeEnum.LONG,
            multiplier: 1,
            quantity: item.closeQuantity,
            close: item.closePrice,
            open: item.openPrice,
        }) : undefined;
        return {
            profitLossAmount: profitLossAmount,
            profitLossRate: item.openPrice && profitLossAmount!==undefined ?
                            MathEx.round(profitLossAmount/item.openPrice, 5): undefined,
        }
    }
    static calcUnrealizedProfitLossIbkr (trade: IAutoTrade, currentPrice: number) {
        return this.calcProfitLoss({
            open:trade.ibkr.openPriceAvg,
            close:currentPrice,
            quantity: trade.ibkr.openedQuantity-trade.ibkr.closedQuantity,
            multiplier: this.multiplier(trade),
            isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
        });
    }

    public static calcProfitLoss(p:{open: number, close: number, quantity: number, multiplier:number, isLong:boolean}) {
        const amount = (p.close - p.open) * p.quantity * p.multiplier;
        return p.isLong ? amount : -amount;
    }

    static multiplier(trade:IAutoTrade) {
        return trade.securityTypeId===AutoTradeSecurityTypeEnum.OPTION?100:1;
    }
}
