import React, {useContext, useEffect, useRef, useState} from "react";
import {ErrorMessage, Field, Formik, FormikContext, useFormikContext} from "formik";

// @TODO: Formik is dead?:
// https://github.com/jaredpalmer/formik/discussions/3526

// Formik is missing a hook to map easily input value to selected option,
// "setFieldValue" map output value and it works fine, but there is not way to populate back properly easily

export interface DropDownType<Label,Value> {
  label?: string | JSX.Element;
  name?: string;
  options: {label:Label, value:Value}[];
  multiple?: boolean;
  onChange?: (v:Value[]|Value)=>void;
  value?: Value[]|Value,

  [key: string]: any; // rest of params
}

export function DropDownGeneric<Label,Value>(props: DropDownType<Label,Value>) {

  const formikContext = useContext(FormikContext);

  const mapSelectedToValue = (e: React.ChangeEvent<HTMLSelectElement>): Value|Value[] => {
    const getByIndex = (val:string)=>{
      const index = parseInt(val);
      return props.options[index].value;
    }
    if (!props.multiple) {
      return getByIndex(e.target.value);
    } else {
      const options = e.target.options;
      const sOpts = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          sOpts.push(getByIndex(`${i}`));
        }
      }
      return sOpts;
    }
  }

  const getInputValue = ()=>{
    let val:Value|Value[]|undefined = props.value==null ? undefined : props.value;
    if (val===undefined && formikContext && props.name) {
      const data = formikContext.getFieldProps({name: props.name});
      val = data.value;
    }
    return val;
  }

  const getSelectedIndex = (val:Value|Value[]|undefined)=> {
    let index;
    if (!props.multiple) {
      index = props.options.findIndex((v) => v.value == val);
      if (index === -1) {
        index = undefined;
      }
    } else {
      index = [] as number[];
      (val as [] || [])?.forEach((sVal => {
        const pos = props.options.findIndex((v) => v.value == sVal);
        if (pos !== -1) {
          index.push(pos);
        }
        // console.debug({sVal,pos,opts:props.options});
      }));
    }
    return index;
  }

  const getInputAndMapSelectedIndex = ()=>{
    return getSelectedIndex(getInputValue());
  }

  // use ref field for formik input to save between re-renders, don't use "state" field;
  const selectedIndexRef = useRef(getInputAndMapSelectedIndex());

  const [value, setValue] = useState<number[]|number|undefined>(selectedIndexRef.current);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // context.handleChange(e);
    const outputValue = mapSelectedToValue(e);
    if (formikContext && props.name) {
      formikContext.setFieldValue(props.name, outputValue);
    }
    const selected = getSelectedIndex(outputValue);
    selectedIndexRef.current = selected;
    setValue(selected);
    if (props.onChange) {
      return props.onChange(outputValue);
    }
  };
  const log = (pref:string)=>{
    // console.debug(`${pref} ${props.name}:${JSON.stringify(selectedIndexRef.current)} formik:${!!formikContext}`);
  }
  log('component calling');
  useEffect(()=>{
    log('useEffect calling');
    // re-map if options or input value is changed (options can be dynamic)
    const selected = getInputAndMapSelectedIndex();
    setValue(selected);
    selectedIndexRef.current = selected;
  }, [props.value, props.options]);

  useEffect(()=>{
    log('mount');
    return ()=>{
      log('unmount');
    }
  }, []);
  const label = props.label && props.label !=="" && <>{props.label}</>;
  const opts = (()=> {
    return props.options.map((opt, i) => {
      return (<option key={`code_${i}`} value={i}>{opt.label as any}</option>)
    })
  })();
  const formikField = ()=>{
    return (
      <>
        {label}
        <Field as="select" {...props} onChange={onChange} value={selectedIndexRef.current}>
          {opts}
        </Field>
      </>
    );
  }

  const formField = ()=>{
    return (
      <>
        {label}
        <select {...props} onChange={onChange} value={value as any}>
          {opts}
        </select>
      </>
    );
  }
  return formikContext ? formikField() : formField();

}
