import React from 'react'
import { StrategyFilterOptions } from './Filter'
import { SignalItem, SignalSetup as ISignalSetup } from 'predictagram-lib';
import { SignalSetupRow } from './SignalSetupRow';
import { TradeSetupFilterOptions } from 'components/admin/autotrade/TradeSetupForm';


interface IProps {
  errors: any,
  touched: any,
  values: StrategyFilterOptions,
  setFieldValue: any,
  onOpenSignalDirection?: (direction: "up" | "dn") => void,
  setupName: string,
  remove: any,
  submitForm: any,
}

export const SignalSetupTable: React.FunctionComponent<IProps> = ({
  setupName, // openSignalSetup
  errors,
  touched,
  values,
  setFieldValue,
  remove,
  submitForm,
  onOpenSignalDirection
}) => {
  
  const signalSetup = (values as any)[setupName] as ISignalSetup;

  return (
    <table className="table table-hover table-striped">
    <thead>
      <tr>
        <th>Signal</th>
        <th>Condition</th>
        <th>Exclude</th>
        <th>Repeat Time</th>
        <th>Opp Dir</th>
        <th>Repeat Types</th>
        <th>Direction Types</th>
        <th>Canc Time</th>
        <th>Options</th>
        <th>Del</th>
      </tr>
    </thead>
    <tbody>
      {signalSetup?.signals && signalSetup.signals &&
        signalSetup.signals.map((signalItem: SignalItem, index: number) => {
          return <SignalSetupRow
            onOpenSignalDirection={onOpenSignalDirection}
            setFieldValue={setFieldValue}
            errors={errors}
            index={index}
            remove={remove}
            setupName={setupName}
            signalItem={signalItem}
            touched={touched}
            values={values}
            submitForm={submitForm}
            key={index} />
        })
      }
    </tbody>
  </table>

  )
}
