import {AsyncDropDown, AsyncDropDownType} from "./AsyncDropDown";
import {adminApiServiceCommon} from "../../../../services/AdminApiService";

export function SymbolsDropDown(opts:Partial<AsyncDropDownType<string, number>>) {
  const supplier = async()=>{
      const sgs = await adminApiServiceCommon.getSymbols();
      return sgs.map(s=>{return {label:s.name,value:s.id} });
    }
  return <AsyncDropDown supplier={supplier} {...opts}/>
}
