import React, {useEffect, useRef, useState} from "react";
import {
  ActivationAlgo,
  AutoTradePositionTypeEnum,
  AutoTradeStatusEnum,
  AutoTradeStatusEnumHelper,
  CumulativeChartLines,
  IAutoTrade,
  SignalAlertPerfTypeEnum,
  SignalAlertPerfTypeEnumHelper,
  StockDirectionType
} from "predictagram-lib";
import {
  adminApiServiceCommon,
  adminAutoTradeSetupService,
  adminAutoTradesService
} from "../../../services/AdminApiService";
import {useApiServiceState} from "../../../_hooks/useApiServiceState";
import {Spinner} from "../../common/Spinner";
import {ArrowClockwise} from "react-bootstrap-icons";
import {StockHelper} from "../../../_utils/stock.helper";
import {DatePickerTzEst} from "../../common/DatePickerTz";
import {CumulativeChart} from "../common/CumulativeChart";
import {
  CumulativeChartSignal,
  getAnalysisDefaultLines,
  getChartOptions,
  ICumeDataSearchOptions,
  linesAndAnnotationsOptions
} from "../common/helper";
import {TRADE_CLOSE_COLOR, TRADE_OPEN_COLOR} from "../common/ColoredSignal";
import {IAutoTradeSetupEx} from "../../../interfaces/IAutoTradeSetupEx";
import {SignalAlertPerfScoreFilter} from "../common/filters/SignalAlertPerfScoreFilter";
import {DropDownGeneric} from "../../common/form/DropDownGeneric";
import StatusEnum = ActivationAlgo.StatusEnum;
import {ActivationComboDropDown} from "../common/filters/ActivationComboDropDown";

export const AdminTradesDashboard: React.FunctionComponent = () => {
  const getStockDay = (dt:Date)=>StockHelper.findTradingDay(dt, -1, true)
  const refStockDay = useRef(getStockDay(new Date()));
  const refStatus = useRef(AutoTradeStatusEnum.ACTIVE);
  const [stockDay,setStockDay] = useState(getStockDay(new Date()));
  const {items, state, setSupplier, reload} = useApiServiceState(()=>supplier());
  // const [signalActivationCombo, setSignalActivationCombo] = useState<number[]|undefined>(undefined);
  const [activationCombos, setActivationCombos] = useState<any[]>([]);
  const [signalAlertPerfFilter, setSignalAlertPerfFilter] = useState<SignalAlertPerfTypeEnum | undefined>(undefined);
  const refAlgo = useRef(signalAlertPerfFilter);
  const refActivationCombo = useRef(undefined);


  const supplier = async() => {
    const dataRaw = await adminAutoTradesService.getFilteredData({
      startTime: refStockDay.current.startAt(),
      endTime: refStockDay.current.endAt(),
      autoTradeStatusIds: refStatus.current ? [refStatus.current] : undefined,
      signalAlertActivationAlgoTypeIds: refAlgo.current ? [refAlgo.current] : undefined,
      signalAlertActivationComboIds: refActivationCombo.current,
    });
    const data = dataRaw;
    const setupIds = [...new Set(data.map(t=>t.setupId)).values()];
    const setups = await Promise.all(setupIds.map(id=>adminAutoTradeSetupService.getByIdWithSignalAlert(id)));
    return { trades: data, setups: setups};
  }

  const renderCharts = (data: { setups: IAutoTradeSetupEx[]; trades: IAutoTrade[] })=>{
    const perSymbol = new Map<number,{
      symbolId: number,
      symbolName: string,
      signals:CumulativeChartSignal[],
      trades:IAutoTrade[]
      lines: Set<CumulativeChartLines>,
    }>();
    for (const t of data.trades) {
      if (!perSymbol.has(t.stockSymbolId)) {
        perSymbol.set(t.stockSymbolId, {
          symbolName: t.stockSymbolName,
          symbolId: t.stockSymbolId,
          signals: [],
          trades: [],
          lines: new Set(),
        });
      }
      const ps = perSymbol.get(t.stockSymbolId);
      if (!ps) {
        continue;
      }
      const isLong = t.positionTypeId===AutoTradePositionTypeEnum.LONG;
      const tradeSetup = data.setups.find(ts=>ts.id===t.setupId);
      if (tradeSetup) {
        getAnalysisDefaultLines(tradeSetup.signalAlert.data).map(l=>ps.lines.add(l));
      }

      ps.trades.push(t);
      ps.signals.push({
        x: t.openAt,
        y: t.openPrice,
        direction: isLong ?StockDirectionType.UP:StockDirectionType.DOWN,
        color: TRADE_OPEN_COLOR,
      });
      if (t.statusId===AutoTradeStatusEnum.CLOSED) {
        ps.signals.push({
          x: t.closeAt,
          y: t.closePrice,
          direction: isLong ? StockDirectionType.DOWN: StockDirectionType.UP,
          color: TRADE_CLOSE_COLOR,
        });
      }

    }
    return [...perSymbol.values()].map((v,idx)=>{
      return <div key={idx} style={{ width: "100%", height: "550px" }}>
        {v.symbolName}
        <CumulativeChart
          cumeDataSearchOptions={{
            startTime: refStockDay.current.openAt(),
            endTime: refStockDay.current.closeAt(),
            stockSymbolId:v.symbolId,
          } as ICumeDataSearchOptions}
          params={{
            // showBaseSignals: [],
            extraSignals: v.signals,
            showLines: [...v.lines.values()],
            showOptions: getChartOptions(linesAndAnnotationsOptions),
          }}
        />

      </div>
    })
  }

  // useEffect(()=>{
  //   (async()=> {
  //     const data = await adminApiServiceCommon.getActivationCombos({
  //       signalAlertActivationStatusIds: [StatusEnum.ACTIVE]
  //     });
  //     const options = [
  //       ...data.map(v=>{return {
  //         label:`${v.id} ${SignalAlertPerfTypeEnumHelper.names().get(v.algoTypeId)} ${v.name}`,
  //         value:v.id
  //       }})
  //     ];
  //     setActivationCombos(options);
  //   })();
  // }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <div className="page-title">Trades Dashboard</div>
      </div>
      <div className="d-flex align-items-center gap-4 justify-content-center">
        <div key={'f-date'}>
          <div className="fw-bold">Date</div>
          <DatePickerTzEst
            dateFormat="MM/dd/yyyy"
            onChange={(date: Date) => {
              refStockDay.current = getStockDay(date);
              setStockDay(refStockDay.current);
            }}
            selected={new Date(stockDay.openAt()*1000)}
          />
        </div>
        <div key={'f-status'}>
          <div className="fw-bold">Status:</div>
          <select defaultValue={refStatus.current as any} onChange={(e)=>refStatus.current=(e.target.value ? parseInt(e.target.value):'') as any}>
            <option value=''>All</option>
            {[...AutoTradeStatusEnumHelper.names.entries()].map(s=><option value={s[0]}>{s[1]}</option>)}
          </select>
        </div>
        <div key={'f-algo'}>
          <SignalAlertPerfScoreFilter
            onChange={(v)=>{setSignalAlertPerfFilter(v as SignalAlertPerfTypeEnum);refAlgo.current=v as any}}/>
        </div>
        {/*<DropDownGeneric style={{width:'200px'}} value={signalActivationCombo} multiple={true}  label={<div className="fw-bold">Act. Combo</div>} name={'combo'} options={activationCombos}*/}
        {/*                 onChange={(v)=>{setSignalActivationCombo(v as any);refActivationCombo.current=v as any}}*/}
        {/*/>*/}
        <ActivationComboDropDown multiple={true} onChange={v=>refActivationCombo.current=v as any}/>
        <div key={'submit'}><button className="btn btn-primary" onClick={() => reload()}><ArrowClockwise /></button></div>
      </div>
      <div>&nbsp;</div>
      {state.isLoading() && <Spinner />}
      {state.isError() && <div>{state.error()?.message}</div>}
      {state.isLoaded() && items &&
        renderCharts(items)
      }
    </>
  );
};


