import {DropDownGeneric, DropDownType} from "../../../common/form/DropDownGeneric";
import React, {useEffect, useState} from "react";
import {adminApiServiceCommon} from "../../../../services/AdminApiService";
import {ActivationAlgo, AutoTradeSecurityTypeEnum, SignalAlertPerfTypeEnumHelper} from "predictagram-lib";
import {useApi} from "../../../../_hooks/useApi";
import {useApiServiceState} from "../../../../_hooks/useApiServiceState";
import StatusEnum = ActivationAlgo.StatusEnum;

export const ActivationComboDropDown: React.FunctionComponent<Partial<DropDownType<string,number>>> = ({
 name = 'activationComboId',
 label = <div className="fw-bold">Act. Combo</div>,
 width='200px',
 ...rest
})=> {
  const [options, setOptions] = useState<{label:string,value:number}[]>([])
  const api = useApiServiceState(async()=>adminApiServiceCommon.getActivationCombos({
    // signalAlertActivationStatusIds: [StatusEnum.ACTIVE]
  }))
  useEffect(()=>{
    if (api.state.isLoaded()) {
      const options = [
        ...(api.items||[]).map(v => {
          return {
            label: `${v.id} ${SignalAlertPerfTypeEnumHelper.names().get(v.algoTypeId)} ${v.name}`,
            value: v.id
          }
        })
      ];
      setOptions(options);
    }
  }, [api.state]);

  return <div className="form-group">
    <DropDownGeneric {...rest} style={{width:width}} label={label} name={name} options={options} />
  </div>
}
