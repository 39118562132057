import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  AutoTradeHelper,
  AutoTradeManagementTypeEnumHelper,
  AutoTradePositionTypeEnumHelper,
  AutoTradeStatusEnum,
  AutoTradeStatusEnumHelper, AutoTradeStatusReasonEnumHelper, IAutoTrade,
  IAutoTradeIbkrOrder,
  TriggerExitTypeEnumHelper,
} from 'predictagram-lib';
import { adminApiServiceCommon } from "../../../services/AdminApiService";
import { MessengerContext } from "../../common/messenger";
import { PresetDateRange } from './filters/PresetDateRangeFilter';
import {
  FunnelFill,
  Funnel,
  Grid3x2,
  GraphUpArrow,
  CaretDownFill,
  CaretRightFill,
  ArrowsCollapse,
  ArrowsExpand,
  PlusCircle,
  XCircle,
  ArrowClockwise,
} from 'react-bootstrap-icons'
import { IQuantity, TradeModel } from 'models/trade.model';
import { ExitOverlay } from './ExitOverlay';
import { formatUSCurrency, colorize, TradeSetupNameWithOptionType } from './Trades';
import { LinkAdminTradeSetup } from './LinkAdminTradeSetup';
import { useAdminAutoTradeIbkrPositions } from '_hooks/useAdminAutoTradeIbkrPositions';
import { ManualTradeGroupModal } from './ManualTradeGroupModal';
import { Helper } from '_utils';
import { ManualTradeCloseModal } from './ManualTradeCloseModal';
import { useTrades } from './hooks/useTrades';
import { useAdminAutoTradeSetup } from '_hooks/useAdminAutoTradeSetup';
import { IbkrOrder } from './IbkrOrder';
import { NewYorkDate } from 'components/common/NewYorkDate';
import { TradeLivePrice } from './TradeLivePrice';
import { ManualTradeAddModal } from './ManualTradeAddModal';
import { Link } from 'react-router-dom';
import { IConsensusState } from './ConsensusOrdersGroupedPage';
import { IbkrAccountStatus } from './IbkrAccountStatus';

export interface IGroupedTradeSecurity {
  securityId: number,
  securityName: string,
  stockSymbolId: number,
  optionName: string,
  trades: IAutoTrade[],
  activeTrades: number,
}

type TradeGroup = { [key: string]: IAutoTrade[] };

export const TradesListGroupBySecurityPage: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const tradesApi = useTrades();
  const tradeSetupsApi = useAdminAutoTradeSetup({});

  const [tradeSetupId, setTradeSetupId] = useState<number | undefined>(undefined);

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const tradeSetupCellRef = useRef<Array<HTMLDivElement | null>>([]);
  const exitTradeOverlayRef = useRef<HTMLDivElement | null>(null);
  const [expandedTradeGroups, setExpandedTradeGroups] = useState<number[]>([]);

  const [exitOverlayPosition, setExitOverlayPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const securityCellRef = useRef<Array<HTMLDivElement | null>>([]);
  const chartOverlayRef = useRef<HTMLDivElement | null>(null);
  const [chartOverlayPosition, setChartOverlayPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 });

  const [activeTrade, setActiveTrade] = useState<IAutoTrade | undefined>(undefined);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);

  const [showCloseGroupModal, setShowCloseGroupModal] = useState<boolean>(false);

  const [activeOverlaySymbolId, setActiveOverlaySymbolId] = useState<number | undefined>(undefined);
  const [activeOverlayTrades, setActiveOverlayTrades] = useState<IAutoTrade[]>([]);

  const { api: ibkrPositionApi, getById: getPositionsById } = useAdminAutoTradeIbkrPositions();

  const [data, setData] = useState<IAutoTrade[]>([]);
  const [groupedTrade, setGroupedTrade] = useState<IGroupedTradeSecurity[]>([]);

  const [selectedGroup, setSelectedGroup] = useState<IGroupedTradeSecurity | undefined>(undefined);

  const [activeRowTradeId, setActiveRowTradeId] = useState<number | undefined>(undefined);
  const [showRefreshTradeIds, setShowRefreshTradeIds] = useState<number[]>([]);

  useEffect(() => {
    const grouped = tradesApi.filteredData
      .sort((a, b) => {
        const securityA = a.ibkr.openOrders.length > 0 ? getSecurityName(a.ibkr.openOrders[0]) || "" : "";
        const securityB = b.ibkr.openOrders.length > 0 ? getSecurityName(b.ibkr.openOrders[0]) || "" : "";
        return securityA.localeCompare(securityB);
      })
      .reduce((acc: TradeGroup, trade: IAutoTrade) => {
        const key = JSON.stringify({
          securityId: trade.ibkr.openOrders.length > 0 ? trade.ibkr.openOrders[0].orderContractId : 0,
          securityName: trade.ibkr.openOrders.length > 0 ? getSecurityName(trade.ibkr.openOrders[0]) : "<EMPTY>",
          optionName: trade.ibkr.openOrders.length > 0 ? trade.ibkr.openOrders[0].optionName : "",
          stockSymbolId: trade.ibkr.openOrders.length > 0 ? trade.stockSymbolId : 0,
        });
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(trade);
        return acc
      }, {} as TradeGroup)

    const data: IGroupedTradeSecurity[] = Object.entries(grouped).map(([key, items]) => {
      const { securityId, securityName, stockSymbolId, optionName } = JSON.parse(key);
      return {
        securityId,
        securityName,
        optionName,
        stockSymbolId,
        trades: items,
        activeTrades: items.filter(t => t.statusId === AutoTradeStatusEnum.ACTIVE).length
      }
    })

    setGroupedTrade(data);

  }, [tradesApi.filteredData]);

  const closeGroupTrades = async () => {
    if (!selectedGroup) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }

    if (selectedGroup.activeTrades === 0 || selectedGroup.trades.length === 0) {
      msgrContext.setMessage({ body: 'No active trades found.' });
      return;
    }

    try {
      setIsDeleting(true);
      const promises = selectedGroup.trades.filter(t => t.statusId === AutoTradeStatusEnum.ACTIVE).map((t) => adminApiServiceCommon.closeTrade(t.id));
      await Promise.all(promises);
      tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
      tradesApi.filters.status.set(undefined);
      tradesApi.reload();
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsDeleting(false);
      setShowCloseModal(false);
      setShowCloseGroupModal(false);
      setSelectedGroup(undefined);
    }
  }

  const addTrade = async () => {
    if (!activeTrade) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }
    const id = activeTrade.id;
    setIsAdding(true);
    try {
      const res = await adminApiServiceCommon.addTrade(id);
      if (res) {
        msgrContext.setMessage({ body: `Added #${res.id}` });
        tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
        tradesApi.filters.status.set(undefined);
        tradesApi.reload();
      } else {
        console.error(JSON.stringify(res, null, 2));
        throw Error(`unexpected error trying to add`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsAdding(false);
      setShowAddModal(false);
    }
  }

  const getSecurityName = (ibkrOpenOrder: IAutoTradeIbkrOrder | undefined) => {
    if (!ibkrOpenOrder) {
      return undefined;
    }
    return `${ibkrOpenOrder.orderSide} ${ibkrOpenOrder.optionName}`;
  }

  const getSecurityNameOverlay = (securityName: string, stockSymbolId: number, securityId: number, trades: IAutoTrade[]) => {
    return <div className="d-flex gap-1 flex-row flex-nowrap">
      {securityName}


      <Link state={{
        trades,
        stockSymbolId,
        title: securityName,
        isConsensusChart: true,
        tradeSetupId: 0, // @TODO: this won't work because Group-By Security has multiple trade setups
      } as IConsensusState} to={'/admin/autotrade/consensus-orders-grouped'}>
        <GraphUpArrow style={{ color: 'blue' }} />
      </Link>

      {/* {stockSymbolId !== 0 ? <div ref={el => securityCellRef.current[securityId] = el} onMouseEnter={(e) => { 
        onMouseOverSecurity(stockSymbolId, securityId, trades);
        e.stopPropagation() 
        }}>
        <GraphUpArrow style={{ color: 'blue' }} />
      </div> : <></>} */}
    </div>
  }

  const onMouseOverTradeCell = (tradeId: number) => {
    const overlay = exitTradeOverlayRef.current;
    const cell = tradeSetupCellRef.current[tradeId];
    setActiveOverlaySymbolId(undefined);

    if (!overlay || !cell) { return; }

    const cellRect = cell.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    let top = cellRect.top;
    const left = cellRect.left + (cellRect.width * 0.5);

    const overlayHeight = 350;
    if (top + window.scrollY + overlayHeight > window.innerHeight) {
      top = window.innerHeight - overlayHeight;
    }

    setTradeSetupId(tradeId);
    setExitOverlayPosition({ top: top, left })
  }


  const onMouseOverSecurity = (symbolId: number, index: number, trades: IAutoTrade[]) => {
    const overlay = chartOverlayRef.current;
    const cell = securityCellRef.current[index];
    setTradeSetupId(undefined);
    if (!overlay || !cell) { return; }

    const cellRect = cell.getBoundingClientRect();
    const overlayRect = overlay.getBoundingClientRect();
    let top = cellRect.top + window.scrollY;
    const left = 10; //cellRect.left + (cellRect.width * 0.5);

    // check if past the window bottom
    const chartHeight = 400; // @TODO: figure out why overlayRect.height is returning 0 and remove hard-code;

    top = (window.innerHeight - chartHeight) / 2;


    setActiveOverlaySymbolId(symbolId);
    setActiveOverlayTrades(trades);
    setChartOverlayPosition({ top: top, left })
  }


  const confirmAdd = (trade: IAutoTrade) => {
    setActiveTrade(trade);
    setShowAddModal(true);
  }

  const confirmClose = (trade: IAutoTrade) => {
    setActiveTrade(trade);
    setShowCloseModal(true);
  }

  const closeTrade = async () => {
    if (!activeTrade) {
      msgrContext.setMessage({ body: 'Missing Trade. Nothing done.' });
      return;
    }
    const id = activeTrade.id;
    setIsDeleting(true);
    try {
      const res = await adminApiServiceCommon.closeTrade(id);
      if (res) {
        msgrContext.setMessage({ body: `Closed #${id}` });
        tradesApi.filters.presetDateRange.set(PresetDateRange.TODAY);
        tradesApi.filters.status.set(undefined);
        tradesApi.reload();
      } else {
        console.error(JSON.stringify(res, null, 2));
        throw Error(`unexpected error trying to close`);
      }
    } catch (error: any) {
      msgrContext.setMessage({ body: `${(error as Error).message}` });
    } finally {
      setIsDeleting(false);
      setShowCloseModal(false);
    }
  }

  const confirmGroupClose = (group: IGroupedTradeSecurity) => {
    setSelectedGroup(group);
    setShowCloseGroupModal(true);
  }

  const onClickTradeGroup = (groupId: number) => {
    if (expandedTradeGroups.includes(groupId)) {
      setExpandedTradeGroups(expandedTradeGroups.filter(v => v !== groupId));
    } else {
      setExpandedTradeGroups([...expandedTradeGroups, groupId]);
    }
  }

  const renderQuantityDetail = (quantity: IQuantity) => {

    return (
      <div className="d-flex flex-column gap-1 text-nowrap">
        {quantity.total}
        <div>
          {`A:${quantity.active} C:${quantity.closed} O:${quantity.other}`}
        </div>
      </div>
    );
  }

  const renderIbkrPositions = (group: IGroupedTradeSecurity) => {
    const ibkrPositions = getPositionsById(group.securityId);
    if (!ibkrPositions) return <>n/a</>
    return (
      <div>{ibkrPositions.quantity}</div>
    )
  }

  const renderGroup = (group: IGroupedTradeSecurity) => {
    return (
      <tr className="trade-group" key={`row-${group.securityName}`}>
        <td colSpan={3} className="bg-lighter-charcoal">
          <div className="d-flex flex-nowrap gap-1 align-items-center fw-bold">
            <div onClick={(e) => onClickTradeGroup(group.securityId)}>{expandedTradeGroups.includes(group.securityId) ? <CaretDownFill /> : <CaretRightFill />}</div>
            {getSecurityNameOverlay(group.securityName, group.stockSymbolId, group.securityId, group.trades)}
          </div>
        </td>
        <td className="text-end">
          {renderQuantityDetail(TradeModel.getTradesTotalQuantity(group.trades))}
        </td>
        <td className="text-end">
          {ibkrPositionApi.apiState.isLoaded && renderIbkrPositions(group)}
        </td>
        <td className="text-end">
          {formatUSCurrency(TradeModel.getOpenPriceWeightedAverage(group.trades))}
        </td>
        <td className="text-end">
          {formatUSCurrency(TradeModel.getClosePriceWeightedAverage(group.trades))}
        </td>
        <td className="text-end">
          {colorize(formatUSCurrency(TradeModel.getRealizedTradesProfitLossIbkr(group.trades).profitLossAmount))}
        </td>
        <td></td>
        <td>
          <div className="d-flex gap-2">
            {group.securityName !== "<EMPTY>" &&
              <>
                {(group.activeTrades > 0) && <div className="d-flex flex-column gap-1">
                  <button className="btn text-13 bg-red text-white" onClick={() => confirmGroupClose(group)}><XCircle title="Close all trades in this group" /></button>
                </div>}
              </>
            }
          </div>
        </td>
        <td colSpan={2}></td>
        <td className="text-end">{colorize(formatUSCurrency(TradeModel.getUrealizedProfitLossOptionTrades(group.trades, tradesApi.livePrices)))}</td>
        <td colSpan={5} className="text-nowrap"></td>
      </tr>
    );
  }


  const renderTradeDetail = (trades: IAutoTrade[]) => {
    return (
      <>
        {trades.map((trade, index) => <>
          <tr key={`trade-${trade.id}`} data-setup-id={trade.setupId}>
            <td>
              <div className="d-flex gap-1 align-items-center" role="button" onClick={() => { setActiveRowTradeId(trade.id === activeRowTradeId ? undefined : trade.id) }}>
                {trade.id === activeRowTradeId ? <CaretDownFill /> : <CaretRightFill />}
                {trade.id}
              </div>
            </td>
            <td className="text-nowrap">
              <div className="d-flex flex-nowrap gap-1 align-items-center">
                <TradeSetupNameWithOptionType trade={trade} />
                <div ref={el => tradeSetupCellRef.current[trade.setupId] = el} onMouseEnter={(e) => { onMouseOverTradeCell(trade.setupId); e.stopPropagation() }}><Grid3x2 style={{ color: 'blue' }} /></div>
                <div role="button" onClick={() => tradesApi.toggleTradeSetupFilter(trade.setupId)}>{tradesApi.filters.setup.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
                <div><LinkAdminTradeSetup tradeSetupId={trade.setupId} /></div>
              </div>
            </td>
            <td>{AutoTradePositionTypeEnumHelper.names.get(trade.positionTypeId)}</td>
            <td className="text-end">{trade.openQuantity}</td>
            <td></td>
            <td className="text-end">{formatUSCurrency(trade.ibkr.openPriceAvg)}</td>
            <td className="text-end">{formatUSCurrency(trade.ibkr.closePriceAvg)}</td>
            <td className="text-end">{colorize(formatUSCurrency(AutoTradeHelper.calcRealizedProfitLossTradeIbkr(trade)))}</td>
            <td><NewYorkDate dateInMs={trade.openAt * 1000} /></td>
            <td>
              <div className="d-flex gap-2">
                {trade.ibkr.openOrders.length > 0 && trade.ibkr.openOrders[0].optionName && <button className="btn btn-primary text-13" onClick={() => confirmAdd(trade)}><PlusCircle title="Add Trade" /></button>}
                {trade.statusId === AutoTradeStatusEnum.ACTIVE &&
                  <button className="btn text-13 bg-red text-white" onClick={() => confirmClose(trade)}><XCircle title="Close Trade" /></button>
                }
              </div>
            </td>
            {trade.statusId === AutoTradeStatusEnum.ACTIVE ?
              <>{tradesApi.renderLivePrices(trade)}</>
              :
              <>
                {showRefreshTradeIds.includes(trade.id) ?
                  <TradeLivePrice trade={trade} />
                  :
                  <td colSpan={3}>
                    {trade.ibkr.openOrders.length > 0 &&
                      <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary" onClick={() => setShowRefreshTradeIds([
                          ...showRefreshTradeIds,
                          trade.id
                        ])}><ArrowClockwise /></button>
                      </div>
                    }
                  </td>
                }
              </>
            }
            <td>{AutoTradeManagementTypeEnumHelper.names.get(trade.managementTypeId)}</td>
            <td>{trade.closeAt ? <NewYorkDate dateInMs={trade.closeAt * 1000} /> : ''}</td>
            <td>
              <div className="d-flex gap-1">
                {AutoTradeStatusReasonEnumHelper.names.get(trade.statusReasonId)}
                <div role="button" onClick={() => tradesApi.filters.statusReason.value ? tradesApi.filters.statusReason.set(undefined) : tradesApi.filters.statusReason.set(trade.statusReasonId)}>{tradesApi.filters.statusReason.value ? <FunnelFill color='gray' /> : <Funnel color='blue' />}</div>
              </div>
            </td>
            <td>{TriggerExitTypeEnumHelper.names.get(trade.closeTriggerTypeId)}</td>
            <td>{AutoTradeStatusEnumHelper.names.get(trade.statusId)}</td>
          </tr>
          {(trade.ibkr.openOrders.length > 0 || trade.ibkr.closeOrders.length > 0) && trade.id === activeRowTradeId && <tr>
            <td colSpan={21}>
              <div className="d-flex flex-row gap-2">
                {trade.ibkr.openOrders.length > 0 &&
                  <div className="d-flex flex-column border p-3">
                    <div className="fw-bold">Open Orders</div>
                    <IbkrOrder orders={trade.ibkr.openOrders} />
                  </div>
                }
                {trade.ibkr.closeOrders.length > 0 &&
                  <div className="d-flex flex-column border p-3">
                    <div className="fw-bold">Close Orders</div>
                    <IbkrOrder orders={trade.ibkr.closeOrders} />
                  </div>
                }
              </div>
            </td>
          </tr>
          }
        </>

        )}
      </>
    )
  }

  const collapseAll = () => {
    setExpandedTradeGroups([]);
  }

  const expandAll = () => {
    setExpandedTradeGroups(groupedTrade.map(gt => gt.securityId));
  }

  return (
    <>

      {activeTrade && <ManualTradeAddModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        title='Add Trade'
        onConfirm={addTrade}
        trade={activeTrade}
        isSubmitting={isAdding}
      />}

      {activeTrade && <ManualTradeCloseModal
        show={showCloseModal}
        handleClose={() => setShowCloseModal(false)}
        title='Close Trade'
        onConfirm={closeTrade}
        trade={activeTrade}
        isSubmitting={isDeleting}
      />}

      {selectedGroup && <ManualTradeGroupModal
        show={showCloseGroupModal}
        handleClose={() => setShowCloseGroupModal(false)}
        title={`Close ${selectedGroup.activeTrades} ${Helper.pluralize('Trade', selectedGroup.activeTrades)}`}
        onConfirm={closeGroupTrades}
        group={selectedGroup}
        isSubmitting={isDeleting}
      />}

      <div>
        <div className="d-flex gap-3 justify-content-start align-items-center">
          <div className="page-title my-3">Trades By Security</div>
          <IbkrAccountStatus />
        </div>

        {tradesApi.filterComponent}

        <div className="fixed-table-head admin-trades-list">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th colSpan={2}>
                  <div className="d-flex gap-2">
                    <button type="button" className="btn btn-primary" onClick={expandAll}><ArrowsExpand title="Expand All" /></button>
                    <button type="button" className="btn btn-primary" onClick={collapseAll}><ArrowsCollapse title="Collapse All" /></button>
                    <div>
                      Security Name /<br />
                      Trade Setup Name
                    </div>
                  </div>
                </th>
                <th>Position Type</th>
                <th className="text-end">Quantity</th>
                <th className="text-end">IBKR Positions (To Date)</th>
                <th className="text-end">IBKR Open Price</th>
                <th className="text-end">IBKR Close Price</th>
                <th className="text-end">Profit/Loss</th>
                <th>Opened On</th>
                <th>Trade</th>
                <th className="text-end">Bid Price</th>
                <th className="text-end">Ask Price</th>
                <th className="text-end">Last Price/<br />Unrealized P/L</th>
                <th>Exit Mode</th>
                <th>Close On</th>
                <th>Trade Reason</th>
                <th>Exit Trigger</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                groupedTrade.map((group, index) =>
                  <>
                    {renderGroup(group)}
                    {expandedTradeGroups.includes(group.securityId) && renderTradeDetail(group.trades)}
                    <tr><td colSpan={18}></td></tr>
                  </>
                )
              }
            </tbody>
          </table>
        </div>

        <div ref={exitTradeOverlayRef} onMouseLeave={(e) => {
          setTradeSetupId(undefined);
          e.stopPropagation();
        }} style={{ position: "absolute", top: exitOverlayPosition.top, left: exitOverlayPosition.left }}>
          {tradeSetupId && <ExitOverlay tradeSetupId={tradeSetupId} onEscape={() => setTradeSetupId(undefined)} />}
        </div>

      </div>
    </>
  )
}
