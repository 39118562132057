import { Url } from "_constants";

export class UrlHelper {

  static getSymbolUrl (symbol: string) {
    return Url.PUBLIC_STOCK_PREDICTIONS.replaceAll(":stockSymbol", symbol);
  }

  static getProfileUrl (userId: number) {
    return `${Url.PUBLIC_STOCK_PREDICTOR}/${userId}`;
  }

  static getUserStatSymbol (userId: number) {
    return Url.USER_STATS_SYMBOL.replaceAll(":userId", userId.toString())
  }

  static getUserStatTypes (userId: number, symbol: string) {
    return Url.USER_STATS_PREDICTION_TYPE.replaceAll(':userId', userId.toString()).replaceAll(':symbol', symbol)
  }

  static getAdminEditSecurityBulk(securityId: number) {
    return Url.ADMIN_TRADEGRAM_EDIT_SECURITY_BULK.replace(':securityId', securityId.toString());
  }

  static getExpandedPrediction (predictionId: number) {
    return `${Url.USER_STOCK_EXPANDED.replace(':predictionId', predictionId.toString())}`;
  }

  static getAdminSignalAlertEdit (signalId: number) {
    return `${Url.ADMIN_ANALYSIS_STRATEGY}?id=${signalId}`;
  }

  static getAdminSignalAlertAdd () {
    return `${Url.ADMIN_ANALYSIS_STRATEGY}`;
  }

  static getAdminAutoTradeSetupFromStrategy (fromStrategyId: number) {
    return `${Url.ADMIN_AUTOTRADE_SETUP}?strategyId=${fromStrategyId}`;
  }  

  static getAdminAutoTradeSetupEdit (id: number) {
    return `${Url.ADMIN_AUTOTRADE_SETUP}?id=${id}`;
  }  

  static getAdminAutoTrades (setupId?: number) {
    if (!setupId) {
      return `${Url.ADMIN_AUTOTRADE_TRADES}`;  
    }
    return `${Url.ADMIN_AUTOTRADE_TRADES}?setupId=${setupId}`;
  }

  static getAdminCumeHistory (id: number) {
    return `${Url.ADMIN_ANALYSIS_CUME_HISTORY.replace(':signalAlertId', id.toString())}`
  }

  static getAdminConsensusOrders (tradeId: number) {
    return `${Url.ADMIN_AUTOTRADE_CONSENSUS_ORDERS.replace(':tradeId', tradeId.toString())}`
  }

  static getAdminActivationCombo(id?: number) {
    return `${Url.ADMIN_AUTOTRADE_ACTIVATION_COMBO}${id?`?id=${id}`:''}`;
  }

  static getAdminSignalGroup(id?: number) {
    return `${Url.ADMIN_SIGNAL_GROUP}${id?`?id=${id}`:''}`;
  }

}
