import {DropDownGeneric, DropDownType} from "../../../common/form/DropDownGeneric";
import React, {useEffect, useState} from "react";
import {adminApiServiceCommon} from "../../../../services/AdminApiService";
import {ActivationAlgo, AutoTradeSecurityTypeEnum, SignalAlertPerfTypeEnumHelper} from "predictagram-lib";
import {useApi} from "../../../../_hooks/useApi";
import {useApiServiceState} from "../../../../_hooks/useApiServiceState";
import StatusEnum = ActivationAlgo.StatusEnum;

export interface AsyncDropDownType<Label,Value> extends Partial<DropDownType<Label,Value>> {
  supplier: ()=>Promise<{label:Label, value:Value}[]>
  /**
   * Apply changes after supplier returns info
   * @param input
   */
  apply?: (input:{label:Label, value:Value}[])=>Promise<{label:Label, value:Value}[]>
}

export function AsyncDropDown<Label,Value>(propsIn: AsyncDropDownType<Label,Value>) {

  const [options, setOptions] = useState<{label:Label,value:Value}[]>([]);
  const supplier = propsIn.supplier;
  const apply = propsIn.apply;

  const props = {...propsIn} as DropDownType<Label, Value>;
  delete props?.supplier;
  delete props?.apply;

  const api = useApiServiceState(async()=>{
    const data = await supplier();
    return apply ? await apply(data) : data;
  });
  useEffect(()=>{
    if (api.state.isLoaded() && api.items) {
      setOptions(api.items);
    }
  }, [api.state]);

  return <DropDownGeneric {...props} options={options} />
}
