import React, {useRef} from "react";
import {AutoTradeSecurityTypeEnum, IAutoTradeByCombo, NewYorkTz, SignalAlertPerfTypeEnumHelper} from "predictagram-lib";
import {adminApiServiceCommon, IAutoTradeSearchFilter} from "../../../services/AdminApiService";
import {Form, Formik} from "formik";
import {useApiServiceState} from "../../../_hooks/useApiServiceState";
import {Spinner} from "../../common/Spinner";
import {DatePickerTzEst} from "../../common/DatePickerTz";
import {getCoreRowModel, getSortedRowModel, SortingState, useReactTable} from "@tanstack/react-table";
import {ReactTableHelper} from "../../../_utils/ReactTableHelper";
import {DataGrid} from "../common/DataGrid";
import {SecurityTypeDropDown} from "../common/filters/SecurityTypeDropDown";

type Options = IAutoTradeSearchFilter;

const Filter: React.FunctionComponent<{
  initialValues: Options,
  onClick: (search:Options)=>void,
}> = ({ initialValues, onClick }) => {

  const onSubmit = (dataIn: any, actions: any) => {
    const data = Object.assign({}, dataIn);
    onClick({
      ...data,
    });
  }


  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3 my-3">
              <div className="d-flex flex-column">
                {/*<SignalAlertCategoriesDropDown />*/}
                <SecurityTypeDropDown label={<div className="fw-bold">Security</div>} name="autoTradeSecurityTypeId"/>
              </div>
              <div className="d-flex flex-column">
                <span className="fw-bold">Start Date EST (inclusive)</span>
                <DatePickerTzEst
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("startTime", date.getTime() / 1000);
                  }}
                  selected={new Date((values.startTime||0) * 1000)}
                />
                <br />
                <span className="fw-bold">End Date EST (inclusive)</span>
                <DatePickerTzEst
                  dateFormat="MM/dd/yyyy"
                  onChange={(date: Date) => {
                    setFieldValue("endTime", date.getTime() / 1000);
                  }}
                  selected={new Date((values.endTime||0) * 1000)}
                />
              </div>
              <div className="align-self-end">
                <button type="submit" className="btn btn-primary p-2">Update Search</button>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
}


export const AdminTradesByCombos: React.FunctionComponent = () => {

  const todayStart = NewYorkTz.getDateMidnight(new Date()).getTime() / 1000;
  const todayEnd = todayStart + (24 * 60 * 60) - 1; // set to last minute of today
  const [sorting, setSorting] = React.useState<SortingState>([])

  const initialSearch: Options = {
    startTime: todayStart,
    endTime: todayEnd,
  }
  const initialValues = {
    startTime: todayStart,
    endTime: todayEnd,
    autoTradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
  } as Options;
  const searchOptions = useRef(initialSearch);

  type StatItem = IAutoTradeByCombo;
  const supplier = async(ops:Options):Promise<StatItem[]>=> {
    const data = await adminApiServiceCommon.getTradesByScore(ops);
    return data as any;
  }

  const comboApi = useApiServiceState(async()=>adminApiServiceCommon.getActivationCombos({}))


  // const [ options, setOptions ] = useState<Options>(initialSearch);
  const {items, state, setSupplier} = useApiServiceState<StatItem[]>(()=>supplier(initialSearch));

  const columnHelper = ReactTableHelper.createHelper<StatItem>();
  const columns = [
    columnHelper.columnBuilder('activationComboId', {header: 'ID'}).number().build(),
    columnHelper.columnBuilder('typeId', {
      header: 'Algo',
      cell: (info:any)=>info.getValue()?SignalAlertPerfTypeEnumHelper.names().get(info.getValue()):'',
    }).build(),
    columnHelper.columnBuilder('comboName', {
      header: 'Name',
      cell: (info:any)=>{
        return comboApi.items?.find(c=>c.id===info.cell.row.original.activationComboId)?.name
      },
    }).build(),
    columnHelper.columnBuilder('trades', {header: 'trades'}).number().build(),
    columnHelper.columnBuilder('profitLossRate', {header: 'profitLossRate'}).number({fixed:4}).build(),
    columnHelper.columnBuilder('profitLossAmount', {header: 'profitLossAmount'}).number({fixed:2}).build(),

    columnHelper.columnBuilder('ibkrTrades', {header: 'ibkrTrades'}).number().build(),
    columnHelper.columnBuilder('ibkrProfitLossRate', {header: 'ibkrProfitLossRate'}).number({fixed:4}).build(),
    columnHelper.columnBuilder('ibkrProfitLossAmount', {header: 'ibkrProfitLossAmount'}).number({fixed:2}).build(),

  ];


  const table = useReactTable({
    data: items as any || [],
    columns: columns as any,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    // getGroupedRowModel: getGroupedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    // debugTable: true,
  });

  const onClickCallback = (opts: Options) => {
    searchOptions.current = {
      startTime: opts.startTime,
      endTime: opts.endTime,
    } as Options;
    setSupplier(()=>supplier(opts));
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-end gap-3">
          <Filter initialValues={initialValues} onClick={onClickCallback} />
        </div>
      </div>

      {state.isLoading() && <Spinner />}

      {state.isError() && <div>{state.error()?.message}</div>}

      {state.isLoaded() && comboApi.state.isLoaded() &&
       <DataGrid table={table} />
      }
    </>
  );
};


