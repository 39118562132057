import {ISignalAlertPerfScore, SignalAlertPerfTypeEnumHelper} from "predictagram-lib";

export class SignalAlertHelper {
  static getAlertScoreToString(scores: ISignalAlertPerfScore[], fullNames=false) {
    if (!scores?.length) {
      return [];
    }
    return [...scores.map(v=>{
      const tName = SignalAlertPerfTypeEnumHelper.names().get(v.typeId);
      return tName +
             // (v.score!==null?`:${v.score}`:'') +
             (v.activationName?`:${v.activationId||''}-`+(fullNames?v.activationName:v.activationName.substring(0, 9)):'');
    })];
  }
}
