import {
  StockHelper,
  StockSignalsEnum,
  StockSignalsHelper,
  SignalSetup,
  FilterUserAverageScoreRange,
  CumulativeChartLines, Analysis
} from "predictagram-lib";
import { ICumeDataSearchOptions, ILinesAndAnnotations } from "./interfaces";
import { StrategyProfitModel } from "models/strategy-profit.model";
import {Helper} from "../../../../_utils";

export const defaultSymbol = 3;

export const toIntOrDefault = (v: any, def = null) => {
  return v !== '' ? parseInt(v, 10) : def;
}

const lines: CumulativeChartLines[] = [
  CumulativeChartLines.PREDICTION_VALUES,
  CumulativeChartLines.LAST_5PRED_AVG,
  CumulativeChartLines.LAST_3PRED_AVG,
  CumulativeChartLines.LAST_15MIN_AVG,
  CumulativeChartLines.LAST_30MIN_AVG,
]

const extraLines: CumulativeChartLines[] = [
  CumulativeChartLines.SUP_RES_LINES,
  CumulativeChartLines.TREND_LINES,
  CumulativeChartLines.EXTRA_SUP_RES_LINES
]

const lastNHour: CumulativeChartLines[] = [
  CumulativeChartLines.LAST_1H_AVG,
  CumulativeChartLines.LAST_2H_AVG,
  CumulativeChartLines.LAST_3H_AVG,
  CumulativeChartLines.LAST_4H_AVG,
  CumulativeChartLines.MIN_MAX_4H,
  CumulativeChartLines.MIN_MAX_2H,
  CumulativeChartLines.MIN_MAX_2H_ATR,
  CumulativeChartLines.MACD,
  CumulativeChartLines.EMA120,
  CumulativeChartLines.SMA120,
  CumulativeChartLines.VWAP,
  CumulativeChartLines.PIVOT_POINT,
]

const emaLines = [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.EMA120];
const predLines = [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H];
const stockLines = [CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.MIN_MAX_2H_ATR];
export const StockSignalDefaultLines = new Map<StockSignalsEnum, CumulativeChartLines[] >([
  [StockSignalsEnum.SIGNALS_EMA6_PRICE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA12_PRICE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA26_PRICE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA120_PRICE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA6_CANDLE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA12_CANDLE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA26_CANDLE, emaLines],
  [StockSignalsEnum.SIGNALS_EMA120_CANDLE, emaLines],

  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_PRED, predLines],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK, predLines],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK, predLines],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_160PCT_STOCK_INSIDE, predLines],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_INSIDE, predLines],
  [StockSignalsEnum.SIGNALS_2H_MIN_MAX_220PCT_STOCK_3PRED, predLines],

  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_3PRED, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_3PRED, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_3PRED, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_160PCT_STOCK_INSIDE, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_STOCK_INSIDE, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_400PCT_STOCK_INSIDE, stockLines],
  [StockSignalsEnum.SIGNALS_2H_VD30M_220PCT_PRED_OUTSIDE, stockLines],

  [StockSignalsEnum.SIGNALS_SUP_RES_1H_STOCK, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.LAST_1H_AVG]],
  [StockSignalsEnum.SIGNALS_LAST_2H_AVG_STOCK, [CumulativeChartLines.PREDICTION_VALUES, CumulativeChartLines.LAST_2H_AVG]],

  [StockSignalsEnum.CDL_VWAP, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.VWAP]],


  [StockSignalsEnum.CDL_SMA120, [CumulativeChartLines.MIN_MAX_2H_ATR, CumulativeChartLines.SMA120, CumulativeChartLines.VWAP, CumulativeChartLines.SMA120]],

]);
[
  StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_160PCT,
  StockSignalsEnum.CDL_MIN_2H_VD_30M_160PCT,
  StockSignalsEnum.CDL_MAX_2H_VD_30M_160PCT,

  StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_220PCT,
  StockSignalsEnum.CDL_MIN_2H_VD_30M_220PCT,
  StockSignalsEnum.CDL_MAX_2H_VD_30M_220PCT,

  StockSignalsEnum.CDL_MIN_MAX_2H_VD_30M_400PCT,
  StockSignalsEnum.CDL_MIN_2H_VD_30M_400PCT,
  StockSignalsEnum.CDL_MAX_2H_VD_30M_400PCT,
].forEach(s=>StockSignalDefaultLines.set(s, stockLines));

export const getAnalysisDefaultLines = (input:Analysis.InputParams) => {
  const sgs = getAnalysisOpenCloseSignals(input);
  const sigs = [
    ...(sgs.openSignals ? [...sgs.openSignals] : []),
    ...(sgs.closeSignals ? [...sgs.closeSignals] : []),
  ];
  // render lines which are related to the signals
  const showLines = getSignalDefaultLines(Helper.dedupeArray<StockSignalsEnum>(sigs));
  return showLines;
}


export const getAnalysisOpenCloseSignals = (input:Analysis.InputParams) => {
  const openSignals = input?.openSignalSetup?.signals.map(s=>s.type) || [];
  const closeSignals = input?.closeSignalSetup?.signals.map(s=>s.type) || [];
  return {
    openSignals, closeSignals
  }
}
export const getSignalDefaultLines = (signals: StockSignalsEnum[]) => {
  const lines = signals.reduce((acc: CumulativeChartLines[], signal: StockSignalsEnum) => {
    const r = StockSignalDefaultLines.get(signal);
    if (r) {
      acc.push(...r)
    }
    return acc;
  }, []);
  return lines;
}

export enum ChartOptionsList {
  SHOW_AS_CANDLE            = 'showAsCandle',
  SHOW_5M_PRICE_CANDLE      = 'show5minPriceCandle',
  SHOW_LEGEND               = 'showLegend',
  SHOW_HOVER_INFO           = 'showHoverInfo',
}


export const linesAndAnnotationsOptions: ILinesAndAnnotations = {
  
  lines: new Map(lines.map(value =>[value,false])),

  extraLines: new Map(extraLines.map(value =>[value,false])),

  lastNHour: new Map(lastNHour.map(value =>[value,false])),

  chartOptions: new Map([
    [ChartOptionsList.SHOW_LEGEND, true ],
    [ChartOptionsList.SHOW_AS_CANDLE, true ],
    [ChartOptionsList.SHOW_5M_PRICE_CANDLE, false],
    [ChartOptionsList.SHOW_HOVER_INFO, true],
  ]),

}

const prevDay = StockHelper.findTradingDay(new Date(), -1, true);
const wh = StockHelper.workingHours(new Date(prevDay.now() * 1000));

// export const getSignalStockGap = (stockGapIntervalSecs: number, stockGapRate: number): SignalSetup => {
//   const signalSetup = {
//     signals: [
//       { type: StockSignalsEnum.SIGNALS_STOCK_GAP, options: { stockGapIntervalSecs, stockGapRate } },
//     ],
//   } as SignalSetup;
//   return signalSetup
// }

export const defaultCumeSearchOptions = {
  predictionTypes: StrategyProfitModel.defVals.predictionTypes,
  startTime: wh.start.getTimeSec(),
  endTime: wh.end.getTimeSec(),
  startHourEst: 9,
  endHourEst: 15,
  stockSymbolId: defaultSymbol,
  extraSymbolIds: [],
  openSignalSetup: {signals:[]},//getSignalStockGap(60 * 5, 0.001), // @TODO: not sure why it's necessary
  userAverageScoreValue: "",
  userAverageScore: {} as FilterUserAverageScoreRange,
  // userIds: "",
  predictionIds: [],
  predictionUserIds: [],
  predictionFirstMinStepSizeMoveMax: undefined
  // showSupResLinesChecked: false,
} as Partial<ICumeDataSearchOptions>;

export const getChartOptions = (annotations: ILinesAndAnnotations) => {
  return Array.from(annotations.chartOptions.entries()).filter(v=>v[1]).map(v=>v[0]);
}

export const getShowLines = (annotations: ILinesAndAnnotations): CumulativeChartLines[] => {
  return [
    ...Array.from(annotations.lines.entries()),
    ...Array.from(annotations.lastNHour.entries()),
    ...Array.from(annotations.extraLines.entries())
  ].filter(v=>v[1]).map(v=>v[0]);
}

const checkboxNames: Map<string, string> = new Map<string, string>([
    [CumulativeChartLines.LAST_1H_AVG, 'Last 1H Avg'],
    [CumulativeChartLines.LAST_2H_AVG,  'Last 2H Avg'],
    [CumulativeChartLines.LAST_3H_AVG, 'Last 3H Avg'],
    [CumulativeChartLines.LAST_4H_AVG, 'Last 4H Avg'],
    [CumulativeChartLines.MIN_MAX_4H, 'Min/Max 4H'],
    [CumulativeChartLines.MIN_MAX_2H, 'Min/Max 2H'],
    [CumulativeChartLines.MIN_MAX_2H_ATR, 'Min/Max 2H Vd30m'],
    [CumulativeChartLines.MACD, 'MACD'],
    [CumulativeChartLines.EMA120, 'EMA 120'],
    [CumulativeChartLines.SMA120, 'SMA 120'],
    [CumulativeChartLines.VWAP, 'VWAP'],
    [CumulativeChartLines.PIVOT_POINT, 'Pivot Point'],

    [CumulativeChartLines.PREDICTION_VALUES, 'Prediction Values'],
    [CumulativeChartLines.LAST_5PRED_AVG, 'Last 5 Preds Avg'],
    [CumulativeChartLines.LAST_3PRED_AVG, 'Last 3 Preds Avg'],
    [CumulativeChartLines.LAST_15MIN_AVG, 'Last 15Min Avg'],
    [CumulativeChartLines.LAST_30MIN_AVG, 'Last 30Min Avg'],
  
    [CumulativeChartLines.SUP_RES_LINES, 'Show Sup/Res Lines'],
    [CumulativeChartLines.TREND_LINES, 'Show Trend Lines'],
    [CumulativeChartLines.EXTRA_SUP_RES_LINES, 'Show Extra Sup/Res'],

    [ChartOptionsList.SHOW_LEGEND, 'Show Legend'],
    [ChartOptionsList.SHOW_AS_CANDLE, 'Show As Candle'],
    [ChartOptionsList.SHOW_5M_PRICE_CANDLE, 'Show 5-Min Price Candle'],
    [ChartOptionsList.SHOW_HOVER_INFO, 'Show Hover Info'],

  ])

StockSignalsHelper.signalsMap().forEach((value, key) => {
  checkboxNames.set(key, value);
})

export {checkboxNames};
