export enum EnvEnum {
  Test = "test",
  Prod = "production",
  Dev = "development",
  Staging = "staging",
}

export interface IConfigBase {
  apiDomain: string;
  apiBasePath: string;
  tokenName: string;
  wwwDomain: string;
  welcomePagePredictors: number [];
  recaptchaSiteKey: string;
}
export interface IConfig extends IConfigBase {
  environment: EnvEnum;
}
