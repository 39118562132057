import React from "react";
import {SignalAlertPerfTypeEnum, SignalAlertPerfTypeEnumHelper} from "predictagram-lib";
import {DropDownGeneric, DropDownType} from "../../../common/form/DropDownGeneric";

export const SignalAlertPerfScoreFilter: React.FunctionComponent<Partial<DropDownType<string,SignalAlertPerfTypeEnum>>> = ({
  name = 'signalAlertPerfScore',
  label = 'Alert Algo',
  ...rest
})=> {
  const options = Array.from(SignalAlertPerfTypeEnumHelper.names().entries()).map((v,t)=>{
    return {label: v[1] as string,value: v[0] as number}
  });
  options.unshift({label:'All', value: undefined as any});
  // console.debug({options});
  const labelText = ()=>{
    return (
      <div className="fw-bold">{label}</div>
    )
  }
  return (
    <div className="form-group"><DropDownGeneric {...rest} options={options} label={labelText()} name={name} /*onChange={onChange}*/ /></div>
  );
}

