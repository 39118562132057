import {StockDirectionType} from "./analysis";
import {AutoTradeSecurityTypeEnum, SignalAlertCategoryEnum, StockSignalsEnum} from "../../dict";

// tslint:disable-next-line:no-namespace
export namespace ActivationAlgo {

    export enum StatusEnum {
        ACTIVE              = 1,
        PAUSED              = 2,
        PAUSED_ACTIVATE     = 3,
    }

    export class StatusEnumHelper {
        protected static names = new Map<StatusEnum, string>([
            [StatusEnum.ACTIVE,             'Active'],
            [StatusEnum.PAUSED,             'Paused'],
            [StatusEnum.PAUSED_ACTIVATE,    'Paused Activate'],
        ]);

        public static getNames() {
            return this.names;
        }
    }

    export enum Criteria {

        CDL_SIZE_AMOUNT                             = 'cdlSizeAmount',
        PREV_CDL_SIZE_AMOUNT                        = 'prevCdlSizeAmount',
        STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY          = 'stockGapOpenPrevCloseNextDay',
        HIGH_LOW_NEXT_DAY                           = 'highLowNextDay',

        SMA120_DIST                                 = 'SMA120Dist',
        VWAP_DIST                                   = 'VWAPDist',
        EMA6_DIST                                   = 'EMA6Dist',
        EMA12_DIST                                  = 'EMA12Dist',
        EMA26_DIST                                  = 'EMA26Dist',
        EMA120_DIST                                 = 'EMA120Dist',


        CDL_COLOR                                   = 'cdlColor',
        PREV_CDL_COLOR                              = 'prevCdlColor',
        STOCK_GAP_LAST_10_POINTS                    = 'stockGapLast10Pts',
        STOCK_GAP_LAST_5_POINTS                     = 'stockGapLast5Pts',
        STOCK_GAP_LAST_2_DAYS_NEXT_DAY              = 'stockGapLast2DaysNextDay',
        CONSECUTIVE_COLORS                          = 'consecutiveColors',
        EMA6_DIST_NEXT_DAY                          = 'EMA6DistNextDay',

        VOLUME                                      = 'volume',
        PREV_VOLUME                                 = 'prevVolume',

        SMA120_DIST_GROUP                           = 'SMA120DistGroup',
        VWAP_DIST_GROUP                             = 'VWAPDistGroup',
        EMA6_DIST_GROUP                             = 'EMA6DistGroup',
        EMA12_DIST_GROUP                            = 'EMA12DistGroup',
        EMA26_DIST_GROUP                            = 'EMA26DistGroup',
        EMA120_DIST_GROUP                           = 'EMA120DistGroup',

        CDL_COLOR_GROUP                             = 'cdlColorGroup',
        PREV_CDL_COLOR_GROUP                        = 'prevCdlColorGroup',
        STOCK_GAP_LAST_10_POINTS_GROUP              = 'stockGapLast10PtsGroup',
        STOCK_GAP_LAST_5_POINTS_GROUP               = 'stockGapLast5PtsGroup',
        STOCK_GAP_LAST_2_DAYS_NEXT_DAY_GROUP        = 'stockGapLast2DaysNextDayGroup',
        CONSECUTIVE_COLORS_GROUP                    = 'consecutiveColorsGroup',
        EMA6_DIST_NEXT_DAY_GROUP                    = 'EMA6DistNextDayGroup',
        STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY_GROUP    = 'stockGapOpenPrevCloseNextDayGroup',
        HIGH_LOW_NEXT_DAY_GROUP                     = 'highLowNextDayGroup',

        VOLUME_GROUP                                = 'volumeGroup',
        PREV_VOLUME_GROUP                           = 'prevVolumeGroup',

    }

    export enum ComboCondCompare {
        MORE        = '>',
        MORE_EQUAL  = '>=',
        EQUAL       = '=',
        LESS_EQUAL  = '<=',
        LESS        = '<',

    }

    export interface ComboCond {
        cond?: 'AND'|'OR',
        compare: ComboCondCompare,
        value: number,
    }

    export interface IReport {
        alertId:number,
        alertName:string,
        symbolName:string,
        symbolId:number,
        tradedDays:number,
        alertDir:StockDirectionType|null, // null means both
        alertCats:SignalAlertCategoryEnum[],
        scoreLast150:number,
        scoreLast90:number,
        scoreLast30:number,
        scoreLast7:number,
        scoreYesterday:number,
        scoreBeforeYesterday:number,
        tradesLast30:number,
        tradesProfitLast14:number,
        date:string, // yyyy-mm-dd UTC
        hoursAtDate:number[], // can be 1 or several
        aperfScoreAtDate:number|null,
        scoreAtDate:number,
        tradesAtDate:number,

        [Criteria.SMA120_DIST]:number,
        [Criteria.VWAP_DIST]:number,
        [Criteria.EMA6_DIST]:number,
        [Criteria.EMA120_DIST]:number,
        [Criteria.EMA26_DIST]:number,
        [Criteria.EMA120_DIST]:number,
        [Criteria.CDL_COLOR]:number,
        [Criteria.PREV_CDL_COLOR]:number,
        [Criteria.STOCK_GAP_LAST_5_POINTS]:number,
        [Criteria.STOCK_GAP_LAST_10_POINTS]:number,
        [Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY]:number,
        [Criteria.CONSECUTIVE_COLORS]:number,
        [Criteria.EMA6_DIST_NEXT_DAY]:number,
        [Criteria.STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY]: number,
        [Criteria.HIGH_LOW_NEXT_DAY]: number,
        [Criteria.VOLUME]: number,
        [Criteria.PREV_VOLUME]: number,

        qualified: boolean,

        [Criteria.SMA120_DIST_GROUP]:number,
        [Criteria.VWAP_DIST_GROUP]:number,
        [Criteria.EMA6_DIST_GROUP]:number,
        [Criteria.EMA120_DIST_GROUP]:number,
        [Criteria.EMA26_DIST_GROUP]:number,
        [Criteria.EMA120_DIST_GROUP]:number,
        [Criteria.CDL_COLOR_GROUP]:number,
        [Criteria.PREV_CDL_COLOR_GROUP]:number,
        [Criteria.STOCK_GAP_LAST_5_POINTS_GROUP]:number,
        [Criteria.STOCK_GAP_LAST_10_POINTS_GROUP]:number,
        [Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY_GROUP]:number,
        [Criteria.CONSECUTIVE_COLORS_GROUP]:number,
        [Criteria.EMA6_DIST_NEXT_DAY_GROUP]:number,
        [Criteria.STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY_GROUP]: number,
        [Criteria.HIGH_LOW_NEXT_DAY_GROUP]: number,
        [Criteria.VOLUME_GROUP]: number,
        [Criteria.PREV_VOLUME_GROUP]: number,
    }

    export type ICombo = IComboIndicator|IComboRepeatWinner;


    interface IComboAlertIds {
        alertIds?: number[], // include
        alertIdsExclude?: number[], // exclude
    }

    interface IComboAlertDir {
        alertDirection?: StockDirectionType, // Up or Don
    }
    interface IComboSymbol {
        symbolIds?: number[], //  multiselect
    }
    interface IComboAlertCats {
        alertCategory?: SignalAlertCategoryEnum[], // Dropdown
    }

    interface IComboCriteria {
        [Criteria.CDL_COLOR]?: number, // +1 OR -1
        [Criteria.PREV_CDL_COLOR]?: number, // +1 OR -2
        [Criteria.STOCK_GAP_LAST_10_POINTS]?: ComboCond[], // Dropdown Greater than or Less than
        [Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY]?: ComboCond[], // Dropdown Greater than or Less than
        [Criteria.CONSECUTIVE_COLORS]?: ComboCond[], // Dropdown Greater than or Less than
        [Criteria.SMA120_DIST_GROUP]?: number[], // Checklists for -2, -1, 1, 2 SMA120 DistGroup
        [Criteria.VWAP_DIST_GROUP]?: number[], // Checklists for -2, -1, 1, 2 VWAP DistGroup
        [Criteria.EMA6_DIST_GROUP]?: number[], // Checklists for -2, -1, 1, 2 EMA6 DistGroup
        [Criteria.EMA12_DIST_GROUP]?: number[], // Checklists for -2, -1, 1, 2 EMA12 DistGroup
        [Criteria.EMA26_DIST_GROUP]?: number[], // Checklists for -2, -1, 1, 2 EMA26 DistGroup
        [Criteria.EMA120_DIST_GROUP]?: number[], // Checklists for -2, -1, 1, 2 EMA120 DistGroup
        [Criteria.CDL_COLOR_GROUP]?: number[], // Checklists for -2, -1, 1, 2 CDL ColorGroup
        [Criteria.PREV_CDL_COLOR_GROUP]?: number[], // Checklists for -2, -1, 1, 2 Prev CDL ColorGroup
        [Criteria.STOCK_GAP_LAST_10_POINTS_GROUP]?: number[], // Checklists for -2, -1, 1, 2 stock gap last 10ptsGroup
        [Criteria.STOCK_GAP_LAST_5_POINTS_GROUP]?: number[],
        [Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY_GROUP]?: number[], // Checklists for -2, -1, 1, 2 StockGap last2 days NextDGroup
        [Criteria.CONSECUTIVE_COLORS_GROUP]?: number[], // Checklists for -2, -1, 1, 2 #consecutive colorGroup
        [Criteria.EMA6_DIST_NEXT_DAY_GROUP]?: number[], // Checklists for -2, -1, 1, 2 EMA6 Dist NextDGroup
        [Criteria.STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY_GROUP]?: number[], // Checklists for -2, -1, 1, 2 Stock Gap Open-PrevClose NextDGroup
        [Criteria.HIGH_LOW_NEXT_DAY_GROUP]?: number[], // Checklists for -2, -1, 1, 2 High-Low NextDGroup
        [Criteria.VOLUME_GROUP]?: number[],
        [Criteria.PREV_VOLUME_GROUP]?: number[],
    }

    export interface IComboBase extends IComboAlertIds, IComboAlertDir, IComboSymbol, IComboAlertCats {
        securityTypes?: AutoTradeSecurityTypeEnum[],
        runHours?: number[],
        alertOpenSignals?:StockSignalsEnum[], // empty or undef - all, OR cond
    }

    export interface IComboRepeatWinner extends IComboBase, IComboCriteria {
        minLossTradesBefore?: number;
        minProfTradesBefore?: number,
        scoreAfterHist?: ComboCond[],
        tradesAfterHist?: ComboCond[],
        // tradeOpenHours?:number[], // empty or undef - all
        // tradeAfterOpenHoursHist?:number[], // empty or undef - all
    }

    export interface IComboIndicator extends IComboBase, IComboCriteria {
        scoreLast150?: ComboCond[],
        scoreLast90?: ComboCond[], // Dropdown Greater than or Less than
        scoreLast30?: ComboCond[], // Dropdown Greater than or Less than
        scoreLast7?: ComboCond[], // Dropdown Greater than or Less than
        scoreYesterday?: ComboCond[], // Dropdown Greater than or Less than
        scoreBeforeYesterday?: ComboCond[], // Dropdown Greater than or Less than
        tradesLast30?: number, // Min trades Dropdown
        tradesProfitLast14?: number, // Min trades Dropdown
        aperfScore?: ComboCond[], // Dropdown Greater than or Less than
        // date
        alertHours?: number[], // Dropdown
    }
}
