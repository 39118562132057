import React from 'react'
import { StrategyFilterOptions } from './Filter'
import { StrategyProfitModel as model } from 'models/strategy-profit.model'
import { DropDown } from 'components/common/form/DropDown';
import { StockTriggerType } from 'predictagram-lib';
import { TradeSetupFilterOptions } from 'components/admin/autotrade/TradeSetupForm';
import { Helper } from '_utils';
import { Debug } from 'components/common/Debug';
import { SignalSetup } from './SignalSetup';
import { CumulativeForm } from './CumulativeForm';
import { DropDownNumber } from 'components/common/form/DropDownNumber';


interface IProps {
  initialValues: StrategyFilterOptions,
  errors: any,
  touched: any,
  values: StrategyFilterOptions,
  setFieldValue: any,
  onOpenTriggerDirection?: (direction: "up" | "dn") => void,
  onOpenSignalDirection?: (direction: "up" | "dn") => void,
}

export const AnalysisForm: React.FunctionComponent<IProps> = ({
  initialValues,
  errors,
  touched,
  values,
  setFieldValue,
  onOpenTriggerDirection,
  onOpenSignalDirection
}) => {


  const AnalysisSettings = () => {
    return (
      <div className="d-flex justify-content-between flex-column flex-md-row gap-3 border rounded p-3">
        <DropDownNumber setFieldValue={setFieldValue} defaultItem={{ key: '', value: 'None' } as any} enumMap={model.gapDirection} errors={errors} touched={touched} label="Gap Dir." name={'prevCurDayPriceGap[direction]'} />
        <DropDownNumber setFieldValue={setFieldValue} enumMap={model.signalPrevClosePriceThreshold} errors={errors} touched={touched} label="Gap Diff %" name={'prevCurDayPriceGap[diffMinRate]'} />
        <DropDownNumber setFieldValue={setFieldValue} enumMap={model.triggerTypeMap} errors={errors} touched={touched} label="Open By" name={'openTriggerType'} />
        <DropDownNumber setFieldValue={setFieldValue} enumMap={model.groupDirType} errors={errors} touched={touched} label="DirSum Type" name={'groupDirectionType'} />
        <DropDown
            enumMap={model.windowTimeSecs}
            errors={errors} touched={touched}
            label="Window Min" name={'windowTimeSecs'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const selected = e.target.value;
              setFieldValue('windowTimeSecs', Number(selected)); // send zero too, it means "OFF" basically
            }}
        />
        <DropDownNumber setFieldValue={setFieldValue} enumMap={model.windowMinMaxPreds} errors={errors} touched={touched} label="Min # Involved" name={'windowMinElements'} />
        <DropDownNumber setFieldValue={setFieldValue} enumMap={model.windowMinMaxPreds} defaultItem={{ key: "" as any, value: 'Unset' }} errors={errors} touched={touched} label="Max # Involved" name={'windowMaxElements'} />
      </div>
    );
  }

  return (
    <>
      <Debug><pre>VALUES{JSON.stringify(values, null, 2)}</pre></Debug>
      <div className="d-flex flex-row gap-3">
        <div className="d-flex flex-column gap-2 w-100">

          <div className="mb-2"><AnalysisSettings /></div>

          <CumulativeForm
              errors={errors}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              onOpenTriggerDirection={onOpenTriggerDirection}
          />

        </div>
      </div>

      <div style={{ width: "1100px" }} className="d-flex flex-column gap-3 mt-3">

      <div className="d-flex flex-column gap-1">
        {/* OpenSignals */}
        {values.openTriggerType.toString() === StockTriggerType.SIGNAL.toString() &&
          <SignalSetup
            errors={errors}
            setFieldValue={setFieldValue}
            setupName='openSignalSetup'
            title='Open Signals'
            touched={touched}
            values={values}
            onOpenSignalDirection={onOpenSignalDirection}>

            {/* Open Signals Criteria */}
          </SignalSetup>
        }


        {/* CloseSignals */}
        <SignalSetup
          errors={errors}
          setFieldValue={setFieldValue}
          setupName='closeSignalSetup'
          title='Close Signals'
          touched={touched}
          values={values}>

          {/* CloseSignal Critiera */}
          <>
            <div className="d-flex justify-content-start gap-4 mb-2">
              <DropDown
                enumMap={model.closeStepSizeRateList}
                defaultItem={{ key: '', value: 'None' } as any}
                errors={errors}
                touched={touched}
                label="Step Size"
                name={'closeStepSizeRate'} />

              <DropDownNumber setFieldValue={setFieldValue} enumMap={model.takeProfitStopLossRate.takeProfitRate} errors={errors} touched={touched} label="Take Profit %" name={'takeProfitRate'} />
              <DropDownNumber setFieldValue={setFieldValue} enumMap={model.takeProfitStopLossRate.lossRate} errors={errors} touched={touched} label="Stop Loss %" name={'stopLossRate'} />
              <DropDownNumber setFieldValue={setFieldValue} enumMap={model.aheadTimeSecs} errors={errors} touched={touched} label="Ahead Min" name={'aheadTimeSecs'} />

            </div>
          </>
        </SignalSetup>

        {/* <div className="text-10 alert alert-info">* In Signal Alerts, Open and Close Signals use "AND" logic -- all must be met.</div> */}

      </div>
      </div>

    </>
  )
}
