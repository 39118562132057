import { StockSignalsEnum } from "predictagram-lib";
import { checkboxNames } from "./helper";

export const TRADE_OPEN_COLOR = "#0165fc";
export const TRADE_CLOSE_COLOR = '#ffff99';
// export const TRADE_CLOSE_COLOR2 = "#FFEA00";

export const ColoredSignalName: React.FunctionComponent<{ color: string, label: string}> = ({ color, label }) => {
  return (
    <div className="d-flex justify-content-start align-items-center flex-row gap-1">
      <div style={{ width: "10px", height: "10px", backgroundColor: color }}></div>
      <div/* key={`${key}`}*/>{label}</div>
    </div>
  );
}

export const coloredSignalNameMapper = (s: StockSignalsEnum, i: number, sgColor?:string) => {
  // const color = stockSignalsColorMap.get(s) || ['#ffffff', '#ffffff'];
  const defColor = 'rgba(255, 255, 255, 1)';
  const name = checkboxNames.get(s) || s;
  return <ColoredSignalName color={sgColor||defColor} label={name} key={`signal-${i}`} />
}
