import React, {useCallback, useEffect, useState} from 'react'
import {useAdminStockSymbols} from '_hooks/useAdminStockSymbols'
import useEscape from '_hooks/useEscape';
import {Helper} from '_utils';
import {ConsensusHelper} from '_utils/ConsensusHelper';
import {Spinner} from 'components/common/Spinner';
import {
  AutoTradePositionTypeEnum,
  DateEx,
  IAutoTrade, IBKRSide,
  OptionType,
  PointUpDown,
  StockDirectionType as DirectionType,
  StockHelper,
  StockSignalsEnum
} from 'predictagram-lib';
import {TradegramHelper} from '_utils/TradegramHelper';
import {useAdminAutoTradeSetupSingle} from '_hooks/useAdminAutoTradeSetupSingle';
import {getAnalysisInputParams} from '../common/Criteria';
import {StrategyFilterOptions} from '../analysis/strategy-profit/Filter';
import {TradeSetupFilterOptions} from './TradeSetupForm';
import {ConsensusChart} from 'components/public/Stock/ConsensusChart';
import {
  CumulativeChartSignal, getAnalysisDefaultLines,
  getChartOptions,
  getSignalDefaultLines,
  ICumeDataSearchOptions,
  linesAndAnnotationsOptions
} from '../common/helper';
import {ChartModel} from 'models/chart.model';
import {OrderTable} from './OrderTable';
import {CumulativeChart} from "../common/CumulativeChart";
import {TRADE_CLOSE_COLOR, TRADE_OPEN_COLOR} from "../common/ColoredSignal";

export type PointUpDownOrders = PointUpDown & {
  actualOrderDate: number,
  contractPrice: number,
  action: "OPEN" | "CLOSE",
  side: IBKRSide,
  optionName: string,
  optionType: OptionType
}

interface IProps {
  symbolId: number,
  onEscape: () => void,
  trades?: IAutoTrade[],
  tradeSetupId: number,
  isConsensusChart?: boolean,
}

export const ChartOverlay: React.FunctionComponent<IProps> = ({ symbolId, trades, onEscape, tradeSetupId, isConsensusChart = false }) => {
  const { api, getById } = useAdminStockSymbols();

  const { api: tradeSetupApi} = useAdminAutoTradeSetupSingle(tradeSetupId);

  const [orders, setOrders] = useState<PointUpDownOrders[] | undefined>(undefined);
  const [symbolName, setSymbolName] = useState<string>('');
  // need to detect what time range use for chart, it might be an old trade
  const now = trades?.length ? new DateEx(trades[trades.length-1].openAt*1000): new DateEx();
  const consensusDate = StockHelper.workingHours(ConsensusHelper.getConsensusDate(now));
  const consensusStartSec = consensusDate.start.getTimeSec();
  const consensusEndDateSec = consensusDate.end.getTimeSec();

  const [cumeOptions, setCumeOptions] = useState<ICumeDataSearchOptions | undefined>(undefined);

  useEffect(() => {
    if (tradeSetupApi.apiState.isLoaded) {
      const tradeSetup = tradeSetupApi.data;
      const analysisInputParams = getAnalysisInputParams(tradeSetup.signalAlert.data);
      const strategyFilterOptions: Partial<StrategyFilterOptions> = {
        ...analysisInputParams,
      }
      const cumeOptions = {
        startTime: consensusStartSec,
        endTime: consensusEndDateSec,
        extraSymbolIds: [],
        predictionTypes: strategyFilterOptions?.predictionTypes as [],
        stockSymbolId: strategyFilterOptions?.stockSymbolId as number,
        userAverageScore: strategyFilterOptions?.userAverageScore,
        // signals: []
      } as Partial<ICumeDataSearchOptions>
      setCumeOptions(cumeOptions as ICumeDataSearchOptions);
    }
  }, [consensusEndDateSec, consensusStartSec, tradeSetupApi.apiState.isLoaded, tradeSetupApi.data])


  const duringConsensus = useCallback((timesec: number) => {
    return timesec >= consensusStartSec && timesec <= consensusEndDateSec
  }, [consensusEndDateSec, consensusStartSec]);

  const getAnnotationData = useCallback(async (symbolName: string) => {
    if (!trades || symbolName === '') {
      return;
    }

    const openOrders: PointUpDownOrders[] = [];
    const closeOrders: PointUpDownOrders[] = [];

    const openOrdersPromises = trades.map(async (trade: IAutoTrade) => {
      const filledOpenOrders = trade.ibkr.openOrders
        .filter(o => o.orderStatus === 'Filled' && duringConsensus(o.createdAt));

      const orders = await Promise.all(filledOpenOrders.map(async o => {
        const minute = Helper.roundDownEpochToMinute(trade.openAt);
        const option = TradegramHelper.parseOptionContractName(o.optionName);
        const optionType = option?.optionType || OptionType.CALL;
        return {
          x: minute.getTimeSec(),
          y: trade.openPrice, // quote.length > 0 ? quote[0].c : 0,
          isSellSignal: trade.positionTypeId===AutoTradePositionTypeEnum.SHORT,
          actualOrderDate: o.createdAt,
          contractPrice: o.orderPrice,
          action: "OPEN",
          optionName: o.optionName,
          optionType,
          side: o.orderSide,
        } as PointUpDownOrders;
      }));
      openOrders.push(...orders);
    });


    const closeOrderPromises = trades.map(async (trade: IAutoTrade) => {
      const filledOrders = trade.ibkr.closeOrders
        .filter(o => o.orderStatus === 'Filled' && duringConsensus(o.createdAt));

      const filledOpenOrders = trade.ibkr.openOrders
        .filter(o => o.orderStatus === 'Filled' && duringConsensus(o.createdAt));

      const orders = await Promise.all(filledOrders.map(async o => {
        const minute = Helper.roundDownEpochToMinute(trade.closeAt);
        const optionName = filledOpenOrders.find(openOrder => openOrder.orderContractId === o.orderContractId)?.optionName || 'n/a';
        const option = TradegramHelper.parseOptionContractName(optionName);
        const optionType = option?.optionType || OptionType.CALL;
        return {
          x: minute.getTimeSec(),
          y: trade.closePrice, //quote.length > 0 ? quote[0].c : 0,
          isSellSignal: trade.positionTypeId===AutoTradePositionTypeEnum.LONG, // sell for LONG position to close
          actualOrderDate: o.createdAt,
          contractPrice: o.orderPrice,
          action: "CLOSE",
          optionName: optionName,
          optionType,
          side: o.orderSide,
        } as PointUpDownOrders;
      }));
      closeOrders.push(...orders);
    });

    await Promise.all([...openOrdersPromises, ...closeOrderPromises]);

    return [
      ...openOrders,
      ...closeOrders
    ];
  }, [trades, duringConsensus]);

  const load = useCallback(async (symbolName: string) => {
    const annots = await getAnnotationData(symbolName);
    setSymbolName(symbolName);
    setOrders(annots);
  }, [getAnnotationData])

  useEffect(() => {
    if (api.apiState.isLoaded) {
      const symbol = getById(symbolId);
      load(symbol?.name || '');
    }
  }, [load, api.apiState.isLoaded, symbolId])


  useEscape(() => {
    onEscape()
  });

  const render = () => {
    if (api.apiState.isLoading) {
      return <Spinner minHeight={25} />
    }

    if (api.apiState.isLoaded) {
      if (symbolName && tradeSetupApi.data) {

        // render lines which are related to the signals
        const showLines = getAnalysisDefaultLines(tradeSetupApi.data.signalAlert.data);
        const extraSignals: CumulativeChartSignal[] = !orders ? [] : orders.map(order => {
          const cumeSignal: CumulativeChartSignal = {
            direction: order.isSellSignal ? DirectionType.DOWN : DirectionType.UP, // just follow signal alert dir, not IBKR side
            x: order.x,
            y: order.y,
            color: order.action==="OPEN" ? TRADE_OPEN_COLOR : TRADE_CLOSE_COLOR // blue for entry
          }
          return cumeSignal;
        })

        return <div>
          <div className="p-3 text-white" >
            {isConsensusChart ? <>
              <ConsensusChart
                heightOverride={450}
                stockSymbol={symbolName}
                predictionTypes={[]}
                showPredictors={false}
                isCandle={true}
                moreAnnotationData={orders}
              />
            </> : <>
              {cumeOptions && <>
                <div style={{ width: "100%", height: "500px" }}>
                  <CumulativeChart
                    cumeDataSearchOptions={cumeOptions}
                    params={{
                      // showBaseSignals:[], //showBaseSignals,
                      showLines,
                      extraSignals,
                      showOptions: getChartOptions(linesAndAnnotationsOptions),
                    }}
                  />
                </div>
              </>}
            </>}
          </div>
          <div className="mt-3" >
            <div className="d-flex gap-3">
              <div className="d-flex flex-column gap-2">
                <div className="fw-bold text-16 bg-green p-2">Open</div>
                <OrderTable orders={orders?.filter(o=>o.action==='OPEN')} />
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="fw-bold text-16 bg-red p-2">Close</div>
                <OrderTable orders={orders?.filter(o=>o.action==='CLOSE')} />
                </div>

            </div>
          </div>
        </div>
      }
    }


  }

  return (
    <div>
      <div className="bg-black" style={{ width: '100%', height: '525px' }}>
        {render()}
      </div>

    </div>
  )
}
