import React, {useEffect} from 'react';
import {AccountProvider} from 'components/common/AccountProvider';
import {MessengerProvider} from 'components/common/messenger';
import {adminAccountService, adminAuthenticationService} from 'services';
import {AdminBody} from './Body';
import {AdminFooter} from './Footer';
import {AdminHeader} from './Header';
import {config} from 'config';
import {EnvEnum} from "../../../interfaces";

export const AdminLayout: React.FunctionComponent = () => {
  useEffect(()=>{
    document.documentElement.style.backgroundColor = '#fff'; // html element
    document.body.style.backgroundColor = '#fff'; // body element
  },[])
  return (
    <div className="admin-layout container-fluid min-vh-100">
      {config.environment !== EnvEnum.Prod && <div className="devex-indicator text-danger">{config.environment.toUpperCase()} SITE</div>}
      <AccountProvider accountService={adminAccountService} authService={adminAuthenticationService} disableNotification={true}>
        <MessengerProvider>
          <AdminHeader />
          <AdminBody />
          <AdminFooter />
        </MessengerProvider>
      </AccountProvider>
    </div>
  );
}

