import {ActivationAlgo, ISignalAlert} from "../interface";
import ComboCond = ActivationAlgo.ComboCond;
import IComboIndicator = ActivationAlgo.IComboIndicator;
import Criteria = ActivationAlgo.Criteria;
import ComboCondCompare = ActivationAlgo.ComboCondCompare;
import IComboRepeatWinner = ActivationAlgo.IComboRepeatWinner;
import IComboBase = ActivationAlgo.IComboBase;
import IComboBaseAlert = ActivationAlgo.IComboBase;
import {DateEx, DateHelper} from "../utils";
import {AnalysisHelper} from "./analysis.helper";
import {AutoTradeSecurityTypeEnum} from "../dict";

export class ActivationAlgoHelper {
    public static compareCriteria(comboVal:ComboCond[]|number|number[], symbolVal:number) {

        // @ts-ignore
        if (comboVal?.length && Array.isArray(comboVal)) {
            // @ts-ignore
            if (comboVal[0]?.compare) {
                if (!ActivationAlgoHelper.compareCond(comboVal as ComboCond[], symbolVal)) {
                    return false;
                }
            } else {
                // number[]
                if (!comboVal.includes(symbolVal as any)) {
                    return false;
                }
            }
        } else {
            if (comboVal!==symbolVal) {
                return false;
            }
        }
        return true;
    }

    public static compareCond(conds:ActivationAlgo.ComboCond[], val:number|undefined|null) {
        if (!conds || conds.length===0) {
            return true;
        }
        if (val==null) {
            return false;
        }
        let currResult:boolean = null as any;
        for (const [index,c] of conds.entries()) {
            const condOk = (c.compare===ComboCondCompare.MORE_EQUAL && val>=c.value)
                           || (c.compare===ComboCondCompare.LESS_EQUAL && val<=c.value)
                           || (c.compare===ComboCondCompare.MORE && val>c.value)
                           || (c.compare===ComboCondCompare.LESS && val<c.value)
                           || (c.compare===ComboCondCompare.EQUAL && val===c.value);
            const cond = c.cond || 'OR';
            if (index===0) {
                currResult = condOk;
            } else {
                currResult = (cond==='OR' && (currResult||condOk)) || (currResult&&condOk);
            }
        }
        return currResult;
    }

    protected static scoreIndFieldList:string[] = [
        'scoreLast150',
        'scoreLast90',
        'scoreLast30',
        'scoreLast7',
        'scoreYesterday',
        'scoreBeforeYesterday',
        'aperfScore',
    ];

    protected static  tradeIndFieldList:string[] = [
        'tradesLast30',
        'tradesProfitLast14'
    ];
    protected static  indInfo =  {
        scoreFields: this.scoreIndFieldList,
        tradeFields: this.tradeIndFieldList,
    };

    public static indicatorComboInfo() {
        return this.indInfo;
    }

    public static hasNextDayCriteria(combo:IComboIndicator|IComboRepeatWinner) {
        for (const critName of Object.values(Criteria).filter(c=>c.includes('NextDay'))) {
            if (critName in combo) {
                return true;
            }
        }
        return false;
    }

    public static filterBase(a:ISignalAlert, combo:IComboBase) {
        const alertDirection = combo.alertDirection;
        const alertIdsInclude = combo.alertIds||[];
        const alertIdsExclude = combo.alertIdsExclude||[];
        const alertOpenSignals = combo.alertOpenSignals||[];
        const symbolIds = combo.symbolIds || [];
        if (symbolIds.length && !symbolIds.includes(a.stockSymbolId)) {
            return false;
        }
        if (alertDirection) {
            const dir = AnalysisHelper.direction(a.data);
            if (!dir || alertDirection!==dir) {
                return false;
            }
        }
        if (!ActivationAlgoHelper.filterAlertCats(combo, a)) {
            return false;
        }
        if (alertIdsInclude.length) {
            if (!alertIdsInclude.includes(a.id)) {
                return false;
            }
        }
        if (alertIdsExclude.length) {
            if (alertIdsExclude.includes(a.id)) {
                return false;
            }
        }
        if (alertOpenSignals.length) {
            const sgs = a.data.openSignalSetup?.signals;
            if (!sgs?.length) {
                return false;
            } else {
                let exists = false;
                for (const sg of sgs) {
                    if (alertOpenSignals.includes(sg.type)) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    return false;
                }
            }

        }
        return true;
    }

    public static filterAlertCats(combo:IComboIndicator|IComboRepeatWinner, alert:ISignalAlert) {
        if (combo.alertCategory?.length) {
            let catOk = true;
            const alertCats = alert.categories || [];
            combo.alertCategory.forEach(c => {
                if (!alertCats.includes(c)) {
                    catOk = false;
                }
            });
            if (!catOk) {
                return false;
            }
        }
        return true;
    }

    public static filterSecurityTypes(combo:IComboBase, securityType: AutoTradeSecurityTypeEnum) {
        if (!combo.securityTypes?.length) {
            return true;
        }
        return combo.securityTypes.includes(securityType);
    }

    public static filterRunHours(combo:IComboBase, now:DateEx) {
        if (!combo.runHours?.length) {
            return true;
        }
        const hourEst = DateHelper.extractDateTimeUs(now).hour;
        if (combo.runHours.includes(hourEst)) {
            return true;
        }
        return false;
    }

    public static filterIndicatorReport(reportData:ActivationAlgo.IReport[], combo:IComboIndicator) {
        const info = this.indicatorComboInfo();
        return reportData.filter(row=>{
            if (combo.alertIds?.length) {
                if (!combo.alertIds.includes(row.alertId)) {
                    return false;
                }
            }
            if (combo.alertHours?.length) {
                const hours = row.hoursAtDate as number[] || [];
                let pass = false;
                for (const comboHour of combo.alertHours) {
                    if (hours.includes(comboHour)) {
                        pass = true;
                        break;
                    }
                }
                if (!pass) {
                    return false;
                }
            }

            if (combo.alertDirection && combo.alertDirection!==row.alertDir) {
                return false;
            }
            if (combo.symbolIds?.length) {
                if (!combo.symbolIds.includes(row.symbolId)) {
                    return false;
                }
            }

            if (combo.alertCategory?.length) {
                for (const cCat of combo.alertCategory) {
                    if (!row.alertCats.length || !row.alertCats?.includes(cCat)) {
                        return false;
                    }
                }
            }

            for (const scoreName of info.scoreFields) {
                if (scoreName in combo) {
                    // @ts-ignore
                    const comboVal = combo[scoreName];
                    // @ts-ignore
                    const rowVal = row[scoreName];
                    if (rowVal==null) {
                        return false;
                    }
                    if (!ActivationAlgoHelper.compareCond(comboVal, rowVal)) {
                        return false;
                    }
                }
            }

            for (const tradeName of info.tradeFields) {
                if (tradeName in combo) {
                    // @ts-ignore
                    const comboVal = combo[tradeName];
                    // @ts-ignore
                    const rowVal = row[tradeName];
                    if (rowVal<comboVal) {
                        return false;
                    }
                }
            }

            for (const critName of Object.values(Criteria)) {
                if (critName in combo) {
                    // @ts-ignore
                    const comboVal = combo[critName];
                    // @ts-ignore
                    const rowVal = row[critName];
                    if (!ActivationAlgoHelper.compareCriteria(comboVal, rowVal)) {
                        return false;
                    }
                }
            }
            return true;
        });
    }

}
