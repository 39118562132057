import {IConfigBase} from "interfaces";

const config: IConfigBase = {
  apiDomain: 'https://portal-api.devex.predictagram.com',
  apiBasePath: '/v1',
  tokenName: 'tk',
  wwwDomain: 'https://www.devex.predictagram.com',
  welcomePagePredictors: [
    5778236988776206,
    2386082696051706,
    3197096396682166,
    6628961913465212,
    7975941944026083,
    4244147144086743,
    2073648768298239,   
  ],
  recaptchaSiteKey: '6LeZzDkoAAAAAOOh-I5oaPA0l-AMQRIOalFSz1BK'
}

export default config;
