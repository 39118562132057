import React from 'react'
import { StrategyFilterOptions } from './Filter'
import { StrategyProfitModel as model } from 'models/strategy-profit.model'
import { FieldArray } from 'formik';
import { TradeSetupFilterOptions } from 'components/admin/autotrade/TradeSetupForm';
import { SignalSetupTable } from './SignalSetupTable';
import { SignalItem } from 'predictagram-lib';
import {DropDown} from "../../../common/form/DropDown";
import {Helper} from "../../../../_utils";

interface IProps {
  errors: any,
  touched: any,
  values: StrategyFilterOptions,
  setFieldValue: any,
  onOpenSignalDirection?: (direction: "up" | "dn") => void,
  setupName: string,
  title: string,
  submitForm?: any,
  children?: any,
}

export const SignalSetup: React.FunctionComponent<IProps> = ({
  setupName, // openSignalSetup
  title,
  errors,
  touched,
  values,
  setFieldValue,
  onOpenSignalDirection,
  submitForm,
  children,
}) => {



  const items: SignalItem[] = (values as any)[setupName]?.signals || [];

  return (
    <FieldArray name={`${setupName}.signals`}>
      {({ remove, push }: { remove: any, push: any }) => {

        const handleClick = () => {
          if (items.length > 0) {
            push({
              ...model.emptySignalItem,
              condition: items[0].condition
            });
            return;
          }
          push({ ...model.emptySignalItem });
        }

        return (
          <div className="d-flex flex-column gap-2 border rounded p-3">
            <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
              <div className="fw-bold text-15">{title} Setup</div>
              <div className="d-flex gap-2">
                {children}
                <DropDown enumMap={model.signalIntresectionInterval}
                          errors={errors}
                          touched={touched}
                          label="Intersec. Interval" name={`${setupName}.intersectionSecs`}
                          onChange={(v: any) => { setFieldValue(`${setupName}.intersectionSecs`, Helper.toIntOrDefault(v.target.value)) }}
                />
                <DropDown enumMap={model.signalStrictSequence as Map<any,string>}
                          errors={errors}
                          touched={touched}
                          label="Strict Seq." name={`${setupName}.strictSequence`}
                  // @TODO: buggy drop-down element, have to manually convert types all time
                          onChange={(v: any) => { setFieldValue(`${setupName}.strictSequence`, v.target.value==='true') }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start gap-3 align-items-center">
              <div className="d-flex justify-content-start gap-1">
                <div className="d-flex align-self-end"><button type="button" className="btn btn-primary text-12" onClick={() => handleClick()}>Add {title}</button></div>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap gap-2" style={{ maxWidth: "1200px" }}>

              <SignalSetupTable errors={errors}
                remove={remove}
                setFieldValue={setFieldValue}
                setupName={setupName} touched={touched}
                values={values}
                onOpenSignalDirection={onOpenSignalDirection}
                submitForm={submitForm}
              />

            </div>
          </div>
        )
      }}
    </FieldArray>
  )
}

