import {EnvEnum, IConfig} from "interfaces";

let config: IConfig;

let env: any = process.env.REACT_APP_ENV_CONFIG;
const envs = Object.values(EnvEnum);
//default to first
if (!envs.includes(env)) {
    env = envs[0];
}
//dynamically load by name
config = require('./' + env).default;
config.environment = env; // assign load config type
export { config };
