import { IApiResponse, useApi } from "./useApi";
import {IAutoTradeSetup, ISignalAlert} from "predictagram-lib";
import { useState } from "react";
import {
  adminApiServiceCommon,
  IAutoTradeSetupSearchFilter
} from "services/AdminApiService";

// export type IAutoTradeSetupEx = IAutoTradeSetup/*&{signalAlert:ISignalAlert}*/;
export interface IAdminAutoTradeSetupApi {
  api: IApiResponse<IAutoTradeSetup[], IAutoTradeSetupSearchFilter>;
  getById: (id: number) => IAutoTradeSetup | undefined;
}

export function useAdminAutoTradeSetup (filter:IAutoTradeSetupSearchFilter): IAdminAutoTradeSetupApi {
  const [data, setData] = useState<IAutoTradeSetup[]>([]);
  const load = async () => {
    const data =  await adminApiServiceCommon.getTradeSetups({
      filters: filter,
    }) as IAutoTradeSetup[];
    setData(data);
    return data;
  }
  return {
    api: useApi(load, filter),
    getById: (id: number) => {return data.find(v => v.id === id)}
  }
}

