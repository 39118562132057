import React, {useContext, useEffect, useRef, useState} from 'react'
import {useAdminStockSymbols} from '_hooks/useAdminStockSymbols';
import {UrlHelper} from '_utils/UrlHelper';
import {ErrorMessage} from 'components/common/ErrorMessage';
import {Spinner} from 'components/common/Spinner';
import {
  AutoTradeSecurityTypeEnumHelper,
  AutoTradeSetupStatusEnum,
  AutoTradeSetupStatusEnumHelper,
  IAutoTradeSetup,
  IBKROrderType,
  OptionType,
  StockHelper
} from 'predictagram-lib';
import {Link, useNavigate} from 'react-router-dom';
import {adminApiServiceCommon, adminAutoTradeSetupService, IAutoTradeSetupSearchFilter} from 'services/AdminApiService';
import {TradeSetupStatusFilter} from './filters/TradeSetupStatusFilter';
import {StockSymbolFilter} from './filters/StockSymbolFilter';
import {IAdminSignalAlertApi, useAdminSignalAlerts} from '_hooks/useAdminSignalAlerts';
import {cacheStats} from '../analysis/alerts/List';
import {ArrowClockwise, Check2Square, Clock, Square} from 'react-bootstrap-icons';
import {IbkrOrderTypeDropDown} from './filters/IbkrOrderTypeDropDown';
import {MessengerContext} from 'components/common/messenger';
import {TradeModel} from 'models/trade.model';
import {onClickHistory} from './TradesListPage';
import {AlertSummaryModel, FilterOptions} from '../analysis/alert-summary-report/alert-summary.model';
import {SecurityTypeDropDown} from "../common/filters/SecurityTypeDropDown";
import {StartEndDateFilter} from "../common/filters/StartEndDateFilter";
import {useApiServiceState} from "../../../_hooks/useApiServiceState";
import {UtilsHelper} from "predictagram-lib/dist/utils/utils.helper";

export const SignalPerformance: React.FunctionComponent<{adminSignalAlerts: IAdminSignalAlertApi, signalId: number|null, simple?: boolean}> = ({adminSignalAlerts, signalId, simple=false}) => {
  const { api: signalApi, getById: getSignalById } = adminSignalAlerts;

  return <>
    {signalApi.apiState.isLoaded ? 
      (()=>{
        // const signalId = TradeModel.getSignalIdFromTradeSetupName(signalAlertId);
        if (signalId) {
          const signal = getSignalById(signalId);
          if (signal) {
            return <div className="d-flex flex-column gap-1">
              {cacheStats(signal, false)}
              {!!!simple && <Link to={UrlHelper.getAdminSignalAlertEdit(signalId)} target="_blank">View Signal</Link>}
            </div>;
          }
        }
        return 'N/A'
      })()
      :
      <>...</>
    }    
  </>;
}

export const TradeSetupListPage:React.FunctionComponent = () => {
  const [tradeSetupStatusFilter, setTradeSetupStatusFilter] = useState<AutoTradeSetupStatusEnum | undefined>(AutoTradeSetupStatusEnum.ACTIVE);
  const [stockSymbolFilter, setStockSymbolFilter] = useState<number | undefined>(undefined);
  const [securityTypeFilter, setSecurityTypeFilter] = useState<number | undefined>(undefined);
  const [searchAlertId, setSearchAlertId] = useState<number | undefined> (undefined);


  const initialSearch: IAutoTradeSetupSearchFilter = {
    autoTradeSetupStatusIds: tradeSetupStatusFilter?[tradeSetupStatusFilter]:undefined,
    stockSymbolId: stockSymbolFilter,
    autoTradeSecurityTypes: securityTypeFilter?[securityTypeFilter]:undefined,
    signalAlertIds: searchAlertId ? [searchAlertId]:undefined,
    // startTime: startDate,
    // endTime: endDate,
    // limit: 100,
    // orderBy: [{column:'id', order:'desc'}],
    // extraColumns: false,
  }

  const supplier = async (filter:IAutoTradeSetupSearchFilter) => {
    return adminApiServiceCommon.getTradeSetups({filters: filter})
  }
  const searchOptions = useRef(initialSearch);
  const setupsApi = useApiServiceState(()=>supplier(initialSearch));

  // const { api } = useAdminAutoTradeSetup({
  //   autoTradeSetupStatusIds: tradeSetupStatusFilter?[tradeSetupStatusFilter]:undefined,
  //   stockSymbolId: stockSymbolFilter,
  //   autoTradeSecurityTypes: securityTypeFilter?[securityTypeFilter]:undefined,
  // });
  const {api: symbolApi, getById: getBySymbolId} = useAdminStockSymbols();
  //const {api: signalApi, getById: getSignalById} = useAdminSignalAlerts();
  const adminSignalAlerts = useAdminSignalAlerts();
  const msgrContext = useContext(MessengerContext);

  const navigate = useNavigate();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [filteredData, setFilteredData] = useState<IAutoTradeSetup[]>([]);



  const stockDay = StockHelper.findTradingDay(new Date(), -1, false);
  const todayStart = stockDay.openAt();
  const todayEnd = stockDay.closeAt();
  const [startDate, setStartDate] = useState<Date>(new Date(todayStart * 1000));
  const [endDate, setEndDate] = useState<Date>(new Date(todayEnd * 1000));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  useEffect(()=>{
    if (setupsApi.state.isLoaded()) {
      setFilteredData(setupsApi.items||[]);
    }
  },[setupsApi.state]);

  const reloadData = ()=>{
    searchOptions.current = {
      autoTradeSetupStatusIds: tradeSetupStatusFilter?[tradeSetupStatusFilter]:undefined,
      stockSymbolId: stockSymbolFilter,
      autoTradeSecurityTypes: securityTypeFilter?[securityTypeFilter]:undefined,
      signalAlertIds: searchAlertId?[searchAlertId]:undefined,
    };
    setupsApi.setSupplier(()=>supplier(searchOptions.current));
  };

  useEffect(()=>{
    setSelectedIds([]);
  },[filteredData])

  const handleEdit = (id: number) => {
    window.open(UrlHelper.getAdminAutoTradeSetupEdit(id), '_blank');
  }

  const handleDelete = async(id:number)=>{
    if (window.confirm(`Delete item #${id}?`)) {
      const res = await adminApiServiceCommon.deleteTradeSetup(id);
      msgrContext.setMessage({ body: res?'Removed':'Not Removed' }, true);
      if (res) {
        setupsApi.reload();
      }
    }
  }

  const handleShowTrade = (setupId?: number) => {
    navigate(UrlHelper.getAdminAutoTrades(setupId))
  }

  const handleSelected = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(i=>i !== id));
      return;
    }
    setSelectedIds([
      ...selectedIds,
      id
    ])
  }

  const handleSelect = async (statusId: AutoTradeSetupStatusEnum) => {
    try {
      const updatePromises = selectedIds.map((id) => adminAutoTradeSetupService.update({ statusId } as IAutoTradeSetup, id));
      await Promise.all(updatePromises);
      setupsApi.reload(); // refreshes the dataset
      setSelectedIds([]);
    } catch (error: any) {
        console.error(error);            
    }
  }

  const handleSelectAll = () => {
    if (selectedIds.length === filteredData.length) {
      setSelectedIds([]);
    }
    else {
      setSelectedIds([
        ...filteredData.map(i=>i.id)
      ]);
    }
  }

  const updateOrderType = async (v: IBKROrderType, setup: IAutoTradeSetup) => {
    const payload  = UtilsHelper.createCopy(setup.tradeTemplate);
    payload.ibkr.orderType = v;
    try {
      await adminAutoTradeSetupService.update({
        tradeSecurityTypeId: setup.tradeSecurityTypeId,
        tradeTemplate:payload
      } as IAutoTradeSetup, setup.id)
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ body: 'Failed to update.' });
    }
  }

  const onClickRunAlertCombined = async (isCompact=false) => {

    if (selectedIds.length === 0) {
      alert('No signal ids selected');
      return;
    }

    const signalAlertIds = filteredData.map( item => {
        return (selectedIds.includes(item.id) && item.signalAlertId) || 0
    }).filter(d => d !== 0);

    if (!signalAlertIds || signalAlertIds.length === 0) {
      alert('No signal ids found.');
      return;
    }

    const searchOptions: FilterOptions = {
      endTime: endDate.getTime() / 1000,
      startTime: startDate.getTime() / 1000,
      signalAlertIds: [...signalAlertIds]
    }

    try {
      await AlertSummaryModel.generateReport(searchOptions, setStatus, setIsLoading, isCompact);
    } catch (e: any) {
      setIsLoading(false);
      alert('Error retrieving report. Possibly an invalid Signal Alert ID');
      console.error(e);
      setStatus(e.toString());
    }
  }

    return <div>
      <div className="page-title mb-1">Trade Setups</div>



      <div className="d-flex gap-1 my-1">

        <div className="d-flex gap-2 border p-2 flex-column">
          <div className={'fw-bold'}>Change Selected Setups Status To:</div>
          <div className='d-flex'>
          {[...AutoTradeSetupStatusEnumHelper.names.entries()].map(([status,statusName])=>{
            return <div><button key={status} className="btn btn-primary m-sm-1 p-sm-1" onClick={()=>handleSelect(status)}>{statusName}</button></div>
          })}
          </div>
        </div>
        <div className="d-flex gap-2 border p-2">
          <div>
            <TradeSetupStatusFilter setValue={setTradeSetupStatusFilter} value={tradeSetupStatusFilter} />
            <SecurityTypeDropDown label={<div className="fw-bold">Security</div>} value={securityTypeFilter} onChange={(v)=>setSecurityTypeFilter(v as any)}/>
          </div>
          <div>
            <StockSymbolFilter value={stockSymbolFilter} setValue={setStockSymbolFilter}  label={'Stock'}/>
            <div>
              <div className="fw-bold">Alert</div>
              <input placeholder={'alert id'} pattern={"[0-9]+"} value={searchAlertId} size={4} onChange={e=>setSearchAlertId(e.target.value as any ||undefined)}/>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div className={'d-flex align-items-center flex-column'}>
              <div><button type="submit" className="btn btn-primary" onClick={reloadData}><ArrowClockwise /></button></div>
              <div style={{'height':'20px'}}>{setupsApi.state.isLoading() && <Spinner isSmall={true} minHeight={15} />}</div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 border p-2">
          <StartEndDateFilter endDate={endDate} onEndDateChange={setEndDate} startDate={startDate} onStartDateChange={setStartDate} />
          <button className="btn btn-primary m-sm-1 p-sm-1" onClick={()=>onClickRunAlertCombined(false)}>Run Alert Combined Report</button>
          <button className="btn btn-primary m-sm-1 p-sm-1" onClick={()=>onClickRunAlertCombined(true)}>Run Alert Combined Rep. Compact</button>
          {isLoading && <Spinner isSmall={true} minHeight={15} />}
        </div>

      </div>

      {setupsApi.state.isError() && <ErrorMessage className='bg-white'>{setupsApi.state.error()}</ErrorMessage>}

      {filteredData && symbolApi.apiState.isLoaded &&
      <div className="fixed-table-head">
      <table className="table table-striped table-hover compact-table">
        <thead>
          <tr>
            <th><div role="button" onClick={handleSelectAll}>{selectedIds.length > 0 && selectedIds.length === filteredData.length ? <Check2Square /> : <Square />}</div></th>
            <th>ID</th>
            <th>Name</th>
            {/* <th>Emails for Alerts</th> */}
            {/*<th>Symbol</th>*/}
            <th>Security</th>
            <th>Signal Performance</th>
            {/*<th>Effective Hours</th>*/}
            <th className="text-end">Max Active Trades</th>
            <th className="text-end">Quantity per Trade</th>
            <th className="text-end">Max Trades per Day</th>
            <th className="text-end">Trades Open</th>
            {/*<th>Side</th>*/}
            <th>Option Type</th>
            <th>Order Type</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.sort((a, b)=>b.id - a.id).map((item, index) => <tr key={`item-${item.id}`}>
            <td>
            <div role="button" className="text-center"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                handleSelected(item.id);
              }}
            >
              {selectedIds.includes(item.id) ? <Check2Square /> : <Square />}
            </div>
            </td>
            <td>{item.id}</td>
            <td>
              <div className="d-flex gap-1 flex-column">
              <div className="d-flex gap-1">
                {TradeModel.getTradeSetupFullTitle(item.name, item.signalAlertId, item.parentSignalAlertId)}
                {item.signalAlertId && <div role="button" className="text-primary" onClick={()=>onClickHistory(item.signalAlertId)} title="Cumulative Chart History"><Clock /></div>}
              </div>
              <div className="text-12">Created On: {new Date(item.createdAt * 1000).toLocaleString()}</div>
              </div>
            </td>
            {/*<td>{getBySymbolId(item.signalAlert.stockSymbolId)?.name}</td>*/}
            <td>{AutoTradeSecurityTypeEnumHelper.names.get(item.tradeSecurityTypeId)}</td>
            <td className="text-nowrap"><SignalPerformance adminSignalAlerts={adminSignalAlerts} signalId={item.signalAlertId}/></td>
            {/*<td>{item.signalAlert.data.startHourEst} - {item.signalAlert.data.endHourEst}</td>*/}
            <td className="text-end">{item.maxActiveTrades}</td>
            <td className="text-end">{item.quantityPerTrade}</td>
            <td className="text-end">{item.maxTradesPerDay}</td>
            <td className="text-end">{item.tradesOpen}</td>
            {/*<td>{item.tradeTemplate.ibkr?.side}</td>*/}
            <td><div className={`fw-bold text-${item.tradeTemplate.optionType === OptionType.CALL ? 'green' : 'red'}`}>{item.tradeTemplate.optionType}</div></td>
            <td>
              <IbkrOrderTypeDropDown value={item.tradeTemplate.ibkr?.orderType} setValue={(ibkrOrderType) => updateOrderType(ibkrOrderType, item )} />
            </td>
            <td>{AutoTradeSetupStatusEnumHelper.names.get(item.statusId)}</td>
            <td>
              <div className="">
                <div className=""><button type="button" className="btn btn-primary m-sm-1  p-sm-1" onClick={()=>handleEdit(item.id)}>Edit</button></div>
                {!item.lastTradeCreatedAt &&
                 <div className=""><button type="button" className="btn btn-primary m-sm-1  p-sm-1" onClick={()=>handleDelete(item.id)}>Delete</button></div>
                }
              </div>
            </td>
            {/* <td><button type="button" className="btn btn-primary">Delete</button></td> */}
            <td><button type="button" className={`btn btn-primary m-sm-1  p-sm-1`} onClick={()=>handleShowTrade(item.id)}>Show Trades</button></td>
          </tr>)}
          <tr>

          </tr>
        </tbody>
      </table>
      </div>}
      {/* <pre>{JSON.stringify(api.data, null, 2)}</pre> */}

    </div>

}

