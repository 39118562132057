import {IApiResponse, useApi} from "./useApi";
import {adminAutoTradeSetupService} from "services/AdminApiService";
import {useState} from "react";
import {IAutoTradeSetupEx} from "../interfaces/IAutoTradeSetupEx";

// type Signals = {
//   openSignals: StockSignalsEnum[];
//   closeSignals: StockSignalsEnum[];
// }


export interface IAdminAutoTradeSetupSingle {
  api: IApiResponse<IAutoTradeSetupEx, number>,
  // signals: Signals | undefined
}

export function useAdminAutoTradeSetupSingle (id: number): IAdminAutoTradeSetupSingle {

  const [data, setData] = useState< IAutoTradeSetupEx | undefined>(undefined);
  // const [signals, setSignals] = useState<Signals | undefined>(undefined);

  // useEffect(()=>{
  //   if (data) {
  //     const analysisInputParams = getAnalysisInputParams(data.signalAlert.data as TradeSetupFilterOptions);
  //     const openSignals = analysisInputParams?.openSignalSetup?.signals.map(s=>s.type) || [];
  //     const closeSignals = analysisInputParams?.closeSignalSetup?.signals.map(s=>s.type) || [];
  //     setSignals({
  //       openSignals,
  //       closeSignals
  //     });
  //   }
  // },[data])

  const load = async (id: number) => {
    const data = await adminAutoTradeSetupService.getByIdWithSignalAlert(id);
    setData(data);
    return data;
  }


  return {
    api:  useApi<IAutoTradeSetupEx, number>(load, id),
    // signals
  }
}

