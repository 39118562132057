import { IConfigBase } from "interfaces";

const config: IConfigBase = {
  apiDomain: 'https://portal-api.predictagram.com',
  apiBasePath: '/v1',
  tokenName: 'tk',
  wwwDomain: 'https://www.predictagram.com',
  welcomePagePredictors: [
6367921565656440,
8647556663290431,
6015409967135382,
5593390424674749,
4521733921241613,
3345654883470549,
5778236988776206,
2386082696051706,
3197096396682166,
6628961913465212,
7975941944026083,
4244147144086743,
2073648768298239,  
],
recaptchaSiteKey: '6LczLDooAAAAAFsk1NVPn71iLxVwoXS2wQM2pSuS'
}

export default config;

