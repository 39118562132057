export enum IBKROrderType {
  // LMT = 'LMT',
  MKT = 'MKT',
  // MIDPRICE = 'MIDPRICE',

  // custom bidding types
  LMT_PB = 'LMTPB', // LIMIT+ProgressiveBidding
  LMT_RP = 'LMTRP', // Limit+RecommendedPrice
  LMT_RP_PB = 'LMT_RP_PB', // Limit+RecPrice+ProgressiveBidding
  LMT_RP_PBA = 'LMT_RP_PBA', //  Limit+RecPrice+ProgressiveBiddingAggressive
  REL_PB = 'RELPB', // Pegged to Primary https://ibkrcampus.com/ibkr-api-page/order-types/#pegged-to-primary-orders
}

export class IBKROrderTypeHelper {
  static names: Map<IBKROrderType, string> = new Map<IBKROrderType, string>([
    // [IBKROrderType.LMT, 'Limit'],
    // [IBKROrderType.MIDPRICE, 'MidPrice'],
    [IBKROrderType.MKT,             'Market'],
    [IBKROrderType.LMT_PB,          'Limit+Pr.Bidding'],
    [IBKROrderType.LMT_RP,          'Limit+Rec.Price'],
    [IBKROrderType.LMT_RP_PB,       'Limit+Rec.Price+Pr.Bid'],
    [IBKROrderType.LMT_RP_PBA,      'Limit+Rec.Price+Pr.BidA'],
    [IBKROrderType.REL_PB,          'Relative+Pr.Bidding'],
  ])
}
